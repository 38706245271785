import React from "react";

import { Link } from "react-router-dom";
import { HomeOutlined } from "@ant-design/icons";
import { Breadcrumb, Row } from "antd";
import { Title } from "../../components/Styles/GeneralStyles";
import FilterWallet from "./FilterWallet";
import TableWallet from "./TableWallet";

export default function HistoryWallet() {
  const link = [
    {
      title: (
        <>
          <Link to={"/"}>
            <HomeOutlined />
          </Link>
        </>
      ),
    },
    {
      title: (
        <Title>
          <b>Lịch sử thanh toán</b>
        </Title>
      ),
    },
  ];

  return (
    <>
      <Row span={24}>
        <Breadcrumb items={link} />
      </Row>

      <FilterWallet />
      <TableWallet />
    </>
  );
}
