import React from "react";
import { Link } from "react-router-dom";
import { HomeOutlined } from "@ant-design/icons";
import { Title } from "../../components/Styles/GeneralStyles";
import { Breadcrumb, Row } from "antd";

import TableProduct from "./TableProduct";

function Position() {
  const link = [
    {
      title: (
        <>
          <Link to={"/"}>
            <HomeOutlined />
          </Link>
        </>
      ),
    },
    {
      title: (
        <Title>
          <b>Vị trí lắp đặt</b>
        </Title>
      ),
    },
  ];
  return (
    <>
      <Row span={24}>
        <Breadcrumb items={link} />
      </Row>

      <TableProduct />
    </>
  );
}

export default Position;
