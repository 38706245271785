import axios from "../services/Api";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const initialState = {
  dataPayment: [],
  totalSize: null,
  dataPaymentById: {},
  showFilter: false,
  query: {
    email: "",
    startDate: null,
    endDate: null,
  },
  pageSize: {
    page: 0,
    size: 10,
  },
};

export const getDataPayment = createAsyncThunk(
  "get/dataPayment",
  async (param) => {
    const result = await axios.get(
      `buy-history/get?startDate=${param.startDate}&endDate=${param.endDate}&page=${param.page}&size=${param.size}`
    );
    return result;
  }
);

export const sreachDataPayment = createAsyncThunk(
  "sreach/dataPayment",
  async (param) => {
    const result = await axios.get(
      `buy-history/search?query=${param.query}&startDate=${param.startDate}&endDate=${param.endDate}&page=${param.page}&size=${param.size}`
    );
    return result;
  }
);

export const getDataPaymentById = createAsyncThunk(
  "byId/dataPayment",
  async (id) => {
    const result = await axios.get(`buy-history/get-id?id=${id}`);
    return result;
  }
);

export const historyPaymentReducer = createSlice({
  name: "webConnect",
  initialState,
  reducers: {
    handlechangeShowFilter: (state, action) => {
      state.showFilter = action.payload;
    },
    handlechangeQuery: (state, action) => {
      state.query = { ...state.query, ...action.payload } || {};
    },
    handlechangePageSize: (state, action) => {
      state.pageSize = { ...state.pageSize, ...action.payload } || {};
    },
  },
  extraReducers: (builder) => {
    builder
        // getall
        .addCase(getDataPayment.pending, (state) => {
          state.loading = true;
        })
        .addCase(getDataPayment.fulfilled, (state, action) => {
          state.loading = false;
          state.totalSize = action.payload.data.totalSize;
          state.dataPayment = action.payload.data.data;
        })
        .addCase(getDataPayment.rejected, (state) => {
          state.loading = false;
        })

        // search
        .addCase(sreachDataPayment.pending, (state) => {
          state.loading = true;
        })
        .addCase(sreachDataPayment.fulfilled, (state, action) => {
          state.loading = false;
          state.totalSize = action.payload.data.totalSize;
          state.dataPayment = action.payload.data.data;
        })
        .addCase(sreachDataPayment.rejected, (state) => {
          state.loading = false;
        })

        // getbyid
        .addCase(getDataPaymentById.pending, (state) => {
          state.loading = true;
        })
        .addCase(getDataPaymentById.fulfilled, (state, action) => {
          state.loading = false;
          state.dataPaymentById = action.payload.data.data;
        })
        .addCase(getDataPaymentById.rejected, (state) => {
          state.loading = false;
        });
  },
});
export const {
  handlechangeShowFilter,
  handlechangeQuery,
  handlechangePageSize,
} = historyPaymentReducer.actions;

export const selectShowFilter = (state) =>
  state.historyPaymentReducer.showFilter;
export const selectQuery = (state) => state.historyPaymentReducer.query;
export const selectPageSize = (state) => state.historyPaymentReducer.pageSize;

export const selectTotalSize = (state) => state.historyPaymentReducer.totalSize;
export const selectDataPayment = (state) =>
  state.historyPaymentReducer.dataPayment;
export const selectDataHistoryPaymentById = (state) =>
  state.historyPaymentReducer.dataPaymentById;

export default historyPaymentReducer.reducer;
