import axios from "../services/Api";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const initialState = {
  dataDowload: [],
  totalSize: null,
  dataDowloadById: {},
  showFilter: false,
  query: {
    email: "",
    startDate: null,
    endDate: null,
  },
  pageSize: {
    page: 0,
    size: 10,
  },
};

export const getDataDowload = createAsyncThunk("get/DataDowload", async (param) => {
  const result = await axios.get(
      `order/get-all?fromDate=${param.startDate}&toDate=${param.endDate}&page=${param.page}&size=${param.size}`
  );
  return result;
});

export const sreachDataDowload = createAsyncThunk("sreach/DataDowload", async (param) => {
  const result = await axios.get(
    `order/get-all?fromDate=${param.startDate}&toDate=${param.endDate}&page=${param.page}&size=${param.size}&query=${param.query}`
  );
  return result;
});

export const getDataDowloadById = createAsyncThunk("byId/DataDowload", async (id) => {
  const result = await axios.get(`order/get-id?id=${id}`);
  return result;
});

export const historyDowloadReducer = createSlice({
  name: "historyDowload",
  initialState,
  reducers: {
    handlechangeShowFilter: (state, action) => {
      state.showFilter = action.payload;
    },
    handlechangeQuery: (state, action) => {
      state.query = { ...state.query, ...action.payload } || {};
    },
    handlechangePageSize: (state, action) => {
      state.pageSize = { ...state.pageSize, ...action.payload } || {};
    },
  },
  extraReducers: (builder) => {
    builder
        // getall
        .addCase(getDataDowload.pending, (state) => {
          state.loading = true;
        })
        .addCase(getDataDowload.fulfilled, (state, action) => {
          state.loading = false;
          state.totalSize = action.payload.data.totalSize;
          state.dataDowload = action.payload.data.data;
        })
        .addCase(getDataDowload.rejected, (state) => {
          state.loading = false;
        })

        // search
        .addCase(sreachDataDowload.pending, (state) => {
          state.loading = true;
        })
        .addCase(sreachDataDowload.fulfilled, (state, action) => {
          state.loading = false;
          state.totalSize = action.payload.data.totalSize;
          state.dataDowload = action.payload.data.data;
        })
        .addCase(sreachDataDowload.rejected, (state) => {
          state.loading = false;
        })

        // getbyid
        .addCase(getDataDowloadById.pending, (state) => {
          state.loading = true;
        })
        .addCase(getDataDowloadById.fulfilled, (state, action) => {
          state.loading = false;
          state.dataDowloadById = action.payload.data.data;
        })
        .addCase(getDataDowloadById.rejected, (state) => {
          state.loading = false;
        });
  },

});

export const { handlechangeShowFilter, handlechangeQuery, handlechangePageSize } =
  historyDowloadReducer.actions;

export const selectShowFilter = (state) => state.historyDowloadReducer.showFilter;
export const selectQuery = (state) => state.historyDowloadReducer.query;
export const selectPageSize = (state) => state.historyDowloadReducer.pageSize;
export const selectTotalSize = (state) => state.historyDowloadReducer.totalSize;
export const selectDataHistoryDowload = (state) => state.historyDowloadReducer.dataDowload;
export const selectDataHistoryDowloadById = (state) => state.historyDowloadReducer.dataDowloadById;

export default historyDowloadReducer.reducer;
