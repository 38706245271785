// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.trigger {
  color: #333333 !important;
}

.ant-menu-title-content {
  -webkit-user-select: none;
          user-select: none;
}

.ant-tabs-tab-btn {
  -webkit-user-select: none;
          user-select: none;
}

.ant-select-show-search.ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  cursor: text;
  border-color: #b3b3b3;
}

.ant-input::placeholder {
  color: #b3b3b3;
  font-weight: 400;
}
.ant-input-number .ant-input-number-input::placeholder {
  color: #b3b3b3;
  font-weight: 400;
}

.ant-select-selection-item::placeholder {
  color: #b3b3b3;
}

.ant-select-selection-search-input::placeholder {
  color: #b3b3b3;
  font-weight: 400;
}

.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
  color: #b3b3b3;
  font-weight: 400;
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;AAC3B;;AAEA;EACE,yBAAiB;UAAjB,iBAAiB;AACnB;;AAEA;EACE,yBAAiB;UAAjB,iBAAiB;AACnB;;AAEA;;EAEE,YAAY;EACZ,qBAAqB;AACvB;;AAEA;EACE,cAAc;EACd,gBAAgB;AAClB;AACA;EACE,cAAc;EACd,gBAAgB;AAClB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,cAAc;EACd,gBAAgB;AAClB;;AAEA;EACE,cAAc;EACd,gBAAgB;AAClB","sourcesContent":[".trigger {\r\n  color: #333333 !important;\r\n}\r\n\r\n.ant-menu-title-content {\r\n  user-select: none;\r\n}\r\n\r\n.ant-tabs-tab-btn {\r\n  user-select: none;\r\n}\r\n\r\n.ant-select-show-search.ant-select:not(.ant-select-customize-input)\r\n  .ant-select-selector {\r\n  cursor: text;\r\n  border-color: #b3b3b3;\r\n}\r\n\r\n.ant-input::placeholder {\r\n  color: #b3b3b3;\r\n  font-weight: 400;\r\n}\r\n.ant-input-number .ant-input-number-input::placeholder {\r\n  color: #b3b3b3;\r\n  font-weight: 400;\r\n}\r\n\r\n.ant-select-selection-item::placeholder {\r\n  color: #b3b3b3;\r\n}\r\n\r\n.ant-select-selection-search-input::placeholder {\r\n  color: #b3b3b3;\r\n  font-weight: 400;\r\n}\r\n\r\n.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {\r\n  color: #b3b3b3;\r\n  font-weight: 400;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
