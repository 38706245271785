import React, { memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  delDataBlog,
  handleChangeStatusDel,
  handlechangePageSize,
  selectDataBlog,
  selectPageSize,
  selectTotalSize,
} from "../../reducers/blogReducer";
import { Col, Popover, Space, Table, Tooltip, notification } from "antd";
import { ErrorType } from "../../constant/ErrorType";
import { StatusStyles } from "../../components/Styles/StatusStyles";
import { useNavigate } from "react-router-dom";
import imgDefault from "../../assets/android-icon-48x48.png";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import {
  PopoverDelete,
  PopoverEdit,
} from "../../components/Styles/PopoverStyles";
import { RowStyle } from "../../components/Styles/GeneralStyles";
function TableBlog() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const sizeSelector = useSelector(selectTotalSize);
  const pageSize = useSelector(selectPageSize);
  const dataBlog = useSelector(selectDataBlog);

  const dataTable = dataBlog?.map((e) => ({
    key: e.id,
    id: e.id,
    image: e.image,
    title: e.title,
    content: e.content,
    active: e.active,
  }));

  const onChangePageSize = (page, size) => {
    dispatch(
      handlechangePageSize({
        page: page - 1,
        size: size,
      })
    );
  };

  const onShowModalUpd = (record) => {
    navigate(`/updBlog/${record?.id}`, { state: { id: record?.id } });
  };

  const onDeleteBlog = (record) => {
    dispatch(delDataBlog(record.id))
      .unwrap()
      .then((e) => {
        notification.success({
          message: `Xóa bài viết ${ErrorType.SAVE200}`,
        });
        onChangeStatus(record.id);
      })
      .catch((er) => {
        notification.error({
          message: `Xóa bài viết ${ErrorType.ERSAVE400} ${er.statusCode}`,
        });
      });
  };

  const onChangeStatus = (id) => {
    const title = "del" + id.toString();
    dispatch(handleChangeStatusDel(title));
  };

  const columns = [
    {
      title: "STT",
      dataIndex: "stt",
      key: "stt",
      render: (t, r, i) => i + 1,
      width: "50px",
      ellipsis: {
        showTitle: false,
      },
    },
    {
      title: "Hình ảnh",
      dataIndex: "image",
      key: "image",
      ellipsis: {
        showTitle: false,
      },
      render: (value) => (
        <img
          alt=""
          width={32}
          height={30}
          style={{ borderRadius: 5 }}
          src={value ? `${process.env.REACT_APP_API_KEY}/${value}` : imgDefault}
        />
      ),
      width: "100px",
    },
    // {
    //   title: "Tên sản phẩm",
    //   dataIndex: "name",
    //   key: "name",
    //   width: "220px",
    //   ellipsis: { showTitle: false },
    //   render: (value) => (
    //     <Tooltip placement="topLeft" title={value}>
    //       {value}
    //     </Tooltip>
    //   ),
    // },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      width: "120px",
      ellipsis: { showTitle: false },
      render: (value) => (
          <Tooltip placement="topLeft" title={value}>
            {value}
          </Tooltip>
      ),
    },

    {
      title: "Trạng thái",
      dataIndex: "active",
      key: "active",
      width: "80px",
      render: (value) => (
        <StatusStyles dataStatus={value}>
          {value === true ? "Hiện" : "Ẩn"}
        </StatusStyles>
      ),
    },

    {
      title: "Chức năng",
      key: "action",
      fixed: "right",
      width: "120px",
      render: (record) => (
        <Space>
          <PopoverEdit content="Chi tiết và chỉnh sửa thông tin">
            <EditOutlined onClick={() => onShowModalUpd(record)} />
          </PopoverEdit>

          <Popover content="Xóa bài viết">
            <PopoverDelete
              title="Xác nhận xóa"
              onConfirm={() => onDeleteBlog(record)}
            >
              <DeleteOutlined />
            </PopoverDelete>
          </Popover>
        </Space>
      ),
    },
  ];

  return (
    <RowStyle span={24}>
      <Col span={24}>
        <Table
          columns={columns}
          dataSource={dataTable}
          size="small"
          pagination={{
            onChange: onChangePageSize,
            current: pageSize.page + 1,
            pageSize: pageSize?.size,
            total: sizeSelector,
            showSizeChanger: true,
            showTotal: (total) => <div>{`Tổng bản ghi: ${total}`}</div>,
          }}
          scroll={{ x: "calc(350px + 50%)" }}
        />
      </Col>
    </RowStyle>
  );
}

export default memo(TableBlog);
