import React from "react";
import { useDispatch } from "react-redux";
import { Form, Input, message, Modal, notification } from "antd";
import styled from "styled-components";
import { changePassword } from "../../../reducers/authReducer";

export default function ChangePassUserLogin(props) {
  const dispatch = useDispatch();

  const { show, handleCancel } = props;
  const [form] = Form.useForm();

  const onChangpass = async (values) => {
    if (values?.newPassword !== values?.checkPassword) {
      message.error("Xác nhận mật khẩu không đúng");
    } else {
      const body = {
        currentPassword: values?.currentPassword,
        newPassword: values?.newPassword,
      };
      dispatch(changePassword(body))
        .unwrap()
        .then((e) => {
          notification.success({
            message: e.data.message,
          });
          onClose();
        })
        .catch((err) => {
          notification.error({
            message: `Mật khẩu cũ không đúng`,
          });
        });
    }
  };

  const onClose = () => {
    handleCancel();
    form.setFieldsValue({
      currentPassword: "",
      newPassword: "",
      checkPassword: "",
    });
  };
  return (
    <Modal
      title={`Đổi mật khẩu`}
      open={show}
      okButtonProps={{
        form: "insPassUser-form",
        key: "submit",
        htmlType: "submit",
      }}
      onCancel={onClose}
    >
      <Form
        form={form}
        id="insPassUser-form"
        name="nest-messages"
        onFinish={onChangpass}
        autoComplete="off"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
      >
        <FormItem
          label="Mật khẩu cũ"
          name="currentPassword"
          rules={[{ required: true, message: "Nhập mật khẩu cũ" }]}
        >
          <Input.Password placeholder="Nhập mật khẩu cũ" />
        </FormItem>

        <FormItem
          label="Mật khẩu mới"
          name="newPassword"
          rules={[{ required: true, message: "Nhập mật khẩu mới" }]}
        >
          <Input.Password placeholder="Nhập mật khẩu mới" />
        </FormItem>

        <FormItem
          label="Xác nhận mật khẩu mới"
          name="checkPassword"
          rules={[{ required: true, message: "Nhập lại mật khẩu mới" }]}
        >
          <Input.Password placeholder="Xác nhận mật khẩu mới" />
        </FormItem>
      </Form>
    </Modal>
  );
}

const FormItem = styled(Form.Item)`
  font-weight: 500;
`;
