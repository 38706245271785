import axios from "../services/Api";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const initialState = {
    dataCate: [],
    loading: false,
};

export const getCategory = createAsyncThunk(
    "get/DataCategory",
    async (param) => {
        const result = await axios.get(
            `/category/get-category-id-name`
        );
        return result;
    }
);

export const categoryReducer = createSlice({
    name: "otherCate",
    initialState,
    reducers: {
        handlechangeShowFilter: (state, action) => {
            state.showFilter = action.payload;
        },
        handlechangeQuery: (state, action) => {
            state.query = action.payload;
        },
        handlechangePageSize: (state, action) => {
            state.pageSize = { ...state.pageSize, ...action.payload } || {};
        },

        handleChangeStatusDel: (state, action) => {
            state.statusDel = action.payload;
        },
    },
    extraReducers: {
        [getCategory.pending]: (state) => {
            state.loading = true;
        },
        [getCategory.fulfilled]: (state, action) => {
            state.loading = false;
            state.dataCate = action.payload.data.data;
        },
        [getCategory.rejected]: (state) => {
            state.loading = false;
        },

    },
});
export const {
    handlechangeShowFilter,
    handlechangeQuery,
    handlechangePageSize,

    handleChangeStatusDel,
} = categoryReducer.actions;

export const selectDataCate = (state) =>
    state.categoryReducer.dataCate;

export default categoryReducer.reducer;
