import axios from "../services/Api";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const initialState = {
  dataBlogById: {},
  dataBlog: [],
  totalSize: null,

  showFilter: false,
  query: "",
  pageSize: {
    page: 0,
    size: 10,
  },
  statusDel: "",
};

export const getDataBlog = createAsyncThunk(
  "get/DataBlog",
  async (param) => {
    const result = await axios.get(
      `/blog/get-all?page=${param.page}&size=${param.size}`
    );
    return result;
  }
);

export const searchDataBlog = createAsyncThunk(
  "search/DataBlog",
  async (param) => {
    const result = await axios.get(
      `/blog/search?page=${param.page}&size=${param.size}&query=${param.query}`
    );
    return result;
  }
);

export const getDataBlogById = createAsyncThunk(
  "byId/getDataBlogById",
  async (id) => {
    const result = await axios.get(`/blog/get-id?id=${id}`);
    console.log(result.data.data)
    return result;
  }
);

export const insDataBlog = createAsyncThunk(
  "ins/DataBlog",
  async (body, { rejectWithValue }) => {
    try {
      const result = await axios.post("/blog/save", body);
      return result;
    } catch (err) {
      return rejectWithValue(err.data.status);
    }
  }
);

export const delDataBlog = createAsyncThunk(
  "del/DataBlog",
  async (id, { rejectWithValue }) => {
    try {
      const result = await axios.get(`/blog/delete?id=${id}`);
      return result;
    } catch (err) {
      return rejectWithValue(err.data.status);
    }
  }
);

export const blogReducer = createSlice({
  name: "otherBlog",
  initialState,
  reducers: {
    handlechangeShowFilter: (state, action) => {
      state.showFilter = action.payload;
    },
    handlechangeQuery: (state, action) => {
      state.query = action.payload;
    },
    handlechangePageSize: (state, action) => {
      state.pageSize = { ...state.pageSize, ...action.payload } || {};
    },

    handleChangeStatusDel: (state, action) => {
      state.statusDel = action.payload;
    },
  },
  extraReducers: {
    [getDataBlog.pending]: (state) => {
      state.loading = true;
    },
    [getDataBlog.fulfilled]: (state, action) => {
      state.loading = false;
      state.totalSize = action.payload.data.totalSize;
      state.dataBlog = action.payload.data.data;
    },
    [getDataBlog.rejected]: (state) => {
      state.loading = false;
    },

    [searchDataBlog.pending]: (state) => {
      state.loading = true;
    },
    [searchDataBlog.fulfilled]: (state, action) => {
      state.loading = false;
      state.totalSize = action.payload.data.totalSize;
      state.dataBlog = action.payload.data.data;
    },
    [searchDataBlog.rejected]: (state) => {
      state.loading = false;
    },

    //getbyid
    [getDataBlogById.pending]: (state) => {
      state.loading = true;
    },
    [getDataBlogById.fulfilled]: (state, action) => {
      state.loading = false;
      state.dataBlogById = action.payload.data.data;
    },
    [getDataBlogById.rejected]: (state) => {
      state.loading = false;
    },
  },
});

export const {
  handlechangeShowFilter,
  handlechangeQuery,
  handlechangePageSize,

  handleChangeStatusDel,
} = blogReducer.actions;

export const selectShowFilter = (state) => state.blogReducer.showFilter;
export const selectQuery = (state) => state.blogReducer.query;

export const selectPageSize = (state) => state.blogReducer.pageSize;
export const selectStatusDel = (state) => state.blogReducer.statusDel;

export const selectTotalSize = (state) => state.blogReducer.totalSize;
export const selectDataBlogById = (state) =>
  state.blogReducer.dataBlogById;
export const selectDataBlog = (state) => state.blogReducer.dataBlog;

export default blogReducer.reducer;
