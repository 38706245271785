import axios from "../services/Api";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const initialState = {
  token: null,
  role: null,
};

export const login = createAsyncThunk(
  "auth/login",
  async (params, { rejectWithValue }) => {
    try {
      const result = await axios.post("/auth/login", params);
      return result;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const changePassword = createAsyncThunk(
  "auth/changePassword",
  async (body, { rejectWithValue }) => {
    try {
      const result = await axios.post("/user/change-password", body);
      return result;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const authReducer = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logout: (state) => {
      localStorage.clear();
      state.token = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(login.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(login.fulfilled, (state, action) => {
      state.loading = false;
      state.token = action.payload.data.accessToken;
      state.role = action.payload.data.user.authorities[0].authority;
      localStorage.setItem("token", action.payload.data.accessToken);
      localStorage.setItem(
        "role",
        action.payload.data.user.authorities[0].authority
      );
    });
    builder.addCase(login.rejected, (state) => {
      state.loading = false;
    });
  },
});

export const { logout } = authReducer.actions;

export const selectToken = (state) => state.authReducer.token;
export const selectRole = (state) => state.authReducer.role;

export default authReducer.reducer;
