import React, { useState, Suspense } from 'react';
import { Layout } from 'antd';
import { Route, Routes, Navigate } from 'react-router-dom';
import './DefaultLayout.css';
import routes from '../../routes';
import Header from './DefaultHeader';
import Sidebar from './DefaultSidebar';
import styled from 'styled-components';

const { Content } = Layout;

const HomeLayout = () => {
  const [collapsed, setCollapsed] = useState(false);
  const toggle = () => setCollapsed((prev) => !prev);

  const loading = () => <div className="animated fadeIn pt-1 text-center">Loading...</div>;

  return (
      <Layout>
        <Sidebar collapsed={collapsed} />
        <Layout className="site-layout full-screen">
          <Header toggle={toggle} collapsed={collapsed} />
          <ContentStyle>
            <Suspense fallback={loading()}>
              <Routes>
                {routes.map((route, idx) => {
                  return route.component ? (
                      <Route
                          key={idx}
                          path={route.path}
                          element={<route.component />}
                      />
                  ) : null;
                })}
                {/* If no route matches, redirect to a default page */}
                <Route path="*" element={<Navigate to="/" replace />} />
              </Routes>
            </Suspense>
          </ContentStyle>
        </Layout>
      </Layout>
  );
};

export default HomeLayout;

const ContentStyle = styled(Content)`
  margin: 18px;
  padding: 18px;
  border-radius: 10px;
  background-color: #FFFFFF;
  border: 1px solid #D9D9D9;
`;
