import { Button } from "antd";
import styled from "styled-components";

const ButtonSreach = styled(Button)`
  margin-bottom: 5px;
`;

const ButtonRollBack = styled(Button)`
  margin-left: 6px;
  margin-bottom: 5px;
`;

const ButtonLoginWeb = styled(Button)`
  background-color: rgb(55, 181, 157);
`;

export { ButtonSreach, ButtonRollBack, ButtonLoginWeb };
