import React, { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {Breadcrumb, Col, Form, notification, Row, Select, Upload} from "antd";
import { PlusOutlined, LoadingOutlined, HomeOutlined } from "@ant-design/icons";
import { InputNumberStyle, InputStyle, TextAreaStyle } from "../../components/Styles/InputStyles";
import { RowStyle, Title } from "../../components/Styles/GeneralStyles";
import { FormItem } from "../../components/Styles/GeneralStyles";
import {
  getDataProductById, insDataProduct,
  selectDataProductById,
} from "../../reducers/productReducer";
import {Link, useNavigate} from "react-router-dom";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import ButtonAdd from "../../components/ButtonCustom/ButtonAdd";
import {getCategory, selectDataCate} from "../../reducers/categoryReducer";
import {getPosition, selectDataPosition} from "../../reducers/positionReducer";
import {getBrand, selectDataBrand} from "../../reducers/brandReducer";
import {getEndUser, selectDataEndUser} from "../../reducers/endUserReducer";
import {ErrorType} from "../../constant/ErrorType";

function UpdProduct() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = localStorage.getItem("token");
  const id = window.location.href.split("updProduct/")[1];
  const [form] = Form.useForm();
  const dataSelector = useSelector(selectDataProductById);
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState();
  const [status, setStatus] = useState(true);
  const [price, setPrice] = useState("");
  const [salePrice, setSalePrice] = useState("");
  const [salePercentage, setSalePercentage] = useState("");
  const [description, setDescription] = useState("");
  const [active, setActive] = useState(true);
  const [cateId, setCateId] = useState(0);
  const [positionId, setPositionId] = useState(0);
  const [brandId, setBrandId] = useState(0);
  const [endUserId, setEndUserId] = useState(0);
  const [techContent, setTechContent] = useState("");
  const [hot, setHot] = useState(false);

  // const [type, setType] = useState("");
  // const [capacity, setCapacity] = useState("");
  const jwtHeader = {
    Authorization: `Bearer ${token}`,
  };

  const dataCate = useSelector(selectDataCate);
  const dataPosition = useSelector(selectDataPosition);
  const dataBrand = useSelector(selectDataBrand);
  const dataEndUser = useSelector(selectDataEndUser);

  useEffect(() => {
    dispatch(getCategory());
    dispatch(getPosition());
    dispatch(getBrand());
    dispatch(getEndUser());
    if (id) {
      dispatch(getDataProductById(id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    form.setFieldsValue({
      name: dataSelector.name,
      title: dataSelector.title,
      capacity: dataSelector.capacity,
      type: dataSelector.type,
    });
    setDescription(dataSelector.description || "");
    setPrice(dataSelector.price);
    setSalePrice(dataSelector.salePrice);
    setSalePercentage(dataSelector.salePercentage);
    setStatus(dataSelector.status === "Còn hàng" ? true : false);
    setImageUrl(dataSelector.image);
    setActive(dataSelector.active);
    setCateId(dataSelector.cateId);
    setBrandId(dataSelector.brandId);
    setPositionId(dataSelector.positionId);
    setEndUserId(dataSelector.endUserId);
    setTechContent(dataSelector.techContent || "");
    setHot(dataSelector.hot);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataSelector]);

  const onChangePrice = (value) => setPrice(value);
  const onChangeSalePrice = (value) => setSalePrice(value);
  const onChangePercentage = (value) => setSalePercentage(value);
  const handleChangeActive = (value) => setActive(value);

  const handleChangeCate = (value) => {
    setCateId(value);
  };

  const handleChangePosition = (value) => {
    setPositionId(value);
  };

  const handleChangeBrand = (value) => {
    setBrandId(value);
  };

  const handleChangeEndUser = (value) => {
    setEndUserId(value);
  };
  const handleChangeHot = (value) => setHot(value);


  const onFinish = (value) => {
    const body = {
      id: 0,
      name: value.name,
      title: value.title,
      description,
      status: status ? "Còn hàng" : "Hết hàng",
      image: imageUrl,
      price: price ?? 0,
      salePrice: salePrice ?? 0,
      salePercentage: salePercentage ?? 0,
      active: active,
      cateId: cateId,
      positionId: positionId,
      brandId: brandId,
      endUserId: endUserId,
      type: value.type,
      techContent: techContent,
      capacity: value.capacity,
      hot: hot
    };
    dispatch(insDataProduct(body))
        .unwrap()
        .then(() => {
          notification.success({
            message: `Cập nhật sản phẩm ${ErrorType.SAVE200}`,
          });
          handleCancelUpd();
        })
        .catch((er) => {
          notification.error({
            message: `Cập nhật sản phẩm ${ErrorType.ERSAVE400} ${er.message} ${er.statusCode}`,
          });
        });
  };

  const handleCancelUpd = () => {
    form.setFieldsValue({
      name: "",
      code: "",
      title: "",
    });
    setDescription("");
    setPrice("");
    setSalePrice("");
    setSalePercentage("");
    setStatus(true);
    setImageUrl("");
    setLoading(false);
    setActive(true);
    navigate("/product");
  };

  const handleChangeImage = (info) => {
    if (info.file.status === "uploading") {
      setLoading(true);
      return;
    }
    if (info.file.status === "done") {
      const url = info.file.response.path;
      setLoading(false);
      setImageUrl(url);
    }
  };

  const handleChangeStatus = (value) => setStatus(value);

  const API_URL = `${process.env.REACT_APP_API_KEY}`;
  const API_URL_HTTPS = 'https://maylocnuocg7.com:8088';
  const UPLOAD_ENDPOINT = `product/upload-image`;

  function uploadAdapter(loader) {
    return {
      upload: () =>
          new Promise((resolve, reject) => {
            const body = new FormData();
            loader.file.then((file) => {
              body.append("file", file);
              fetch(`${API_URL}/${UPLOAD_ENDPOINT}`, {
                method: "post",
                headers: { Authorization: `Bearer ${token}` },
                body: body,
              })
                  .then((res) => res.json())
                  .then((res) => {
                    resolve({ default: `${API_URL_HTTPS}/${res.path}` });
                  })
                  .catch(reject);
            });
          }),
    };
  }
  function uploadPlugin(editor) {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => uploadAdapter(loader);
  }

  const uploadButton = (
      <div>
        {loading ? <LoadingOutlined /> : <PlusOutlined />}
        <div style={{ marginTop: 8 }}>Upload</div>
      </div>
  );

  const link = [
    {
      title: (
          <>
            <Link to={"/"}>
              <HomeOutlined />
            </Link>
          </>
      ),
    },
    {
      title: (
          <>
            <Link to={"/product"}>
              <b>Sản phẩm</b>
            </Link>
          </>
      ),
    },
    {
      title: (
          <Title>
            <b>Chi tiết sản phẩm</b>
          </Title>
      ),
    },
  ];

  return (
      <>
        <Row span={24}>
          <Breadcrumb items={link}/>
        </Row>

        <RowStyle span={24} style={{marginTop: 20, paddingLeft: 50, paddingRight: 50}}>
          <Col xs={24} sm={24} md={6} lg={6} xl={4} xxl={4}>
            <Upload
                name="file"
                listType="picture-card"
                className="avatar-uploader"
                showUploadList={false}
                action={`${process.env.REACT_APP_API_KEY}/product/upload-image`}
                headers={jwtHeader}
                method="POST"
                onChange={handleChangeImage}
            >
              {imageUrl ? (
                  <img
                      src={`${process.env.REACT_APP_API_KEY}/${imageUrl}`}
                      alt="avatar"
                      style={{
                        width: "100%",
                      }}
                  />
              ) : (
                  uploadButton
              )}
            </Upload>
          </Col>
          <Col xs={24} sm={24} md={18} lg={18} xl={20} xxl={20}>
            <Form
                form={form}
                onFinish={onFinish}
                labelCol={{span: 24}}
                wrapperCol={{span: 24}}
            >
              <FormItem
                  label="Tên Sản phẩm"
                  name="name"
                  rules={[{required: true, message: "Nhập tên sản phẩm"}]}
              >
                <InputStyle placeholder="Tên sản phẩm"/>
              </FormItem>

              <Row span={24}>
                <Col xs={24} sm={24} md={24} lg={8} xl={9} xxl={9} style={{padding: "0px 10px 0px 0px"}}>
                  <FormItem
                      label="Giá bán"
                      rules={[{required: false, message: "Nhập giá bán"}]}
                  >
                    <InputNumberStyle
                        placeholder="Giá bán"
                        value={price}
                        formatter={(value) =>
                            `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                        }
                        parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                        onChange={onChangePrice}
                    />
                  </FormItem>
                </Col>
                <Col xs={24} sm={24} md={24} lg={8} xl={9} xxl={9}>
                  <FormItem
                      label="Giá giảm"
                      rules={[{required: false, message: "Nhập giá giảm "}]}
                  >
                    <InputNumberStyle
                        placeholder="Giá giảm"
                        value={salePrice}
                        formatter={(value) =>
                            `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                        }
                        parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                        onChange={onChangeSalePrice}
                    />
                  </FormItem>
                </Col>
                <Col xs={24} sm={24} md={24} lg={8} xl={6} xxl={6} style={{padding: "0px 0px 0px 10px"}}>
                  <FormItem
                      label="% giảm"
                      rules={[{required: false, message: "Nhập % giảm "}]}
                  >
                    <InputNumberStyle
                        placeholder="% giảm"
                        value={salePercentage}
                        onChange={onChangePercentage}
                    />
                  </FormItem>
                </Col>
              </Row>

              <Row span={24}>
                <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12} style={{padding: "0px 10px 0px 0px"}}>
                  <FormItem
                      label="Danh mục"
                      rules={[{required: true, message: "Nhập danh mục"}]}
                  >
                    <Select
                        onChange={handleChangeCate}
                        placeholder="Chọn danh mục"
                        value={cateId}
                        style={{width: "100%"}}
                        options={dataCate.map(option => ({
                          value: option.id,
                          label: option.name,
                        }))}
                    />
                  </FormItem>
                </Col>
                <Col xs={24} sm={24} md={24} lg={6} xl={6} xxl={6} style={{padding: "0px 10px 0px 0px"}}>
                  <FormItem label="Trạng thái sản phẩm">
                    <Select
                        onChange={handleChangeStatus}
                        placeholder="Chọn trạng thái"
                        style={{width: "100%"}}
                        value={status ? "Còn hàng" : "Hết hàng"}
                        options={[
                          {value: true, label: "Còn hàng"},
                          {value: false, label: "Hết hàng"},
                        ]}
                    />
                  </FormItem>
                </Col>
                <Col xs={24} sm={24} md={24} lg={6} xl={6} xxl={6}>
                  <FormItem label="Trạng thái View">
                    <Select
                        onChange={handleChangeActive}
                        placeholder="Chọn trạng thái view"
                        style={{width: "100%"}}
                        value={active ? "Hiện" : "Ẩn"}
                        options={[
                          {value: true, label: "Hiện"},
                          {value: false, label: "Ẩn"},
                        ]}
                    />
                  </FormItem>
                </Col>
              </Row>
              <Row span={24}>
                <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12} style={{padding: "0px 10px 0px 0px"}}>
                  <FormItem
                      label="Vị trí lắp đặt"
                      rules={[{required: true, message: "Nhập thông tin vị trí lắp đặt"}]}
                  >
                    <Select
                        onChange={handleChangePosition}
                        placeholder="Chọn vị trí"
                        value={positionId}
                        style={{width: "100%"}}
                        options={dataPosition.map(option => ({
                          value: option.id,
                          label: option.name,
                        }))}
                    />
                  </FormItem>
                </Col>
                <Col xs={24} sm={24} md={24} lg={6} xl={6} xxl={6} style={{padding: "0px 10px 0px 0px"}}>
                  <FormItem label="Thương hiệu">
                    <Select
                        onChange={handleChangeBrand}
                        value={brandId}
                        placeholder="Chọn thương hiệu"
                        style={{width: "100%"}}
                        options={dataBrand.map(option => ({
                          value: option.id,
                          label: option.name,
                        }))}
                    />
                  </FormItem>
                </Col>
                <Col xs={24} sm={24} md={24} lg={6} xl={6} xxl={6}>
                  <FormItem label="Đối tượng sử dụng">
                    <Select
                        onChange={handleChangeEndUser}
                        value={endUserId}
                        placeholder="Chọn đối tượng sử dụng"
                        style={{width: "100%"}}
                        options={dataEndUser.map(option => ({
                          value: option.id,
                          label: option.name,
                        }))}
                    />
                  </FormItem>
                </Col>
              </Row>
              {/*Công suất và loại máy*/}
              <Row span={24}>
                <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12} style={{padding: "0px 10px 0px 0px"}}>
                  <FormItem
                      label="Công suất"
                      name="capacity"
                      rules={[{required: true, message: "Nhập công suất"}]}
                  >
                    <InputStyle placeholder="50L"/>
                  </FormItem>
                </Col>
                <Col xs={24} sm={24} md={24} lg={6} xl={6} xxl={6} style={{padding: "0px 10px 0px 0px"}}>
                  <FormItem
                      label="Loại máy"
                      name="type"
                      rules={[{required: true, message: "Nhập loại máy"}]}
                  >
                    <InputStyle placeholder="Nước uống"/>
                  </FormItem>
                </Col>
                <Col xs={24} sm={24} md={24} lg={6} xl={6} xxl={6} style={{padding: "0px 0px 10px 0px"}}>
                  <FormItem label="Sản phẩm hot">
                    <Select
                        onChange={handleChangeHot}
                        placeholder="Chọn sản phẩm hot"
                        style={{width: "100%"}}
                        value={hot ? "Yes" : "No"}
                        options={[
                          {value: true, label: "Yes"},
                          {value: false, label: "No"},
                        ]}
                    />
                  </FormItem>
                </Col>
              </Row>
              <FormItem
                  label="Thông số kĩ thuật"
                  rules={[{required: true, message: "Nhập thông số kĩ thuật"}]}
              >
                <CKEditor
                    editor={ClassicEditor}
                    data={techContent}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      setTechContent(data);
                    }}
                />
              </FormItem>
              <FormItem
                  label="Tiêu đề bài viết"
                  name="title"
                  rules={[{required: false, message: "Nhập tiêu đề bài viết"}]}
              >
                <TextAreaStyle placeholder="Tiêu đề bài viết"/>
              </FormItem>

              <FormItem
                  label="Nội dung bài viết"
                  rules={[{required: false, message: "Nhập nội dung bài viết"}]}
              >
                <CKEditor
                    config={{
                      extraPlugins: [uploadPlugin],
                    }}
                    editor={ClassicEditor}
                    data={description}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      setDescription(data);
                    }}
                />
              </FormItem>

              <Form.Item>
                <ButtonAdd title="Xác nhận"/>
              </Form.Item>
            </Form>
          </Col>
        </RowStyle>
      </>
  );
}

export default memo(UpdProduct);
