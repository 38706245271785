import axios from "../services/Api";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const initialState = {
  dataCategoryPosterSelect: [],
  dataCategoryPosterById: {},
  dataCategoryPoster: [],
  totalSize: null,
  showModalAdd: false,
  showModalUpd: {
    show: false,
    id: null,
  },
  showFilter: false,
  query: "",
  pageSize: {
    page: 0,
    size: 10,
  },
  statusDel: "",
};

export const getDataCategoryPoster = createAsyncThunk(
  "get/DataCategoryPosterPageSize",
  async (param) => {
    const result = await axios.get(`/category/get-category-all`);
    return result;
  }
);

export const sreachDataCategoryPoster = createAsyncThunk(
  "sreach/DataCategoryPoster",
  async (param) => {
    const result = await axios.get(
      `/category/search?page=${param.page}&size=${param.size}&query=${param.query}`
    );
    return result;
  }
);

export const getDataCategoryPosterById = createAsyncThunk("byId/DataCategoryPoster", async (id) => {
  const result = await axios.get(`/category/get-id?id=${id}`);
  return result;
});

export const addCategoryPoster = createAsyncThunk(
  "add/DataCategoryPoster",
  async (body, { rejectWithValue }) => {
    try {
      const result = await axios.post("/category/save", body);
      return result;
    } catch (err) {
      return rejectWithValue(err.data.status);
    }
  }
);

export const delCategoryPoster = createAsyncThunk(
  "del/DataCategoryPoster",
  async (id, { rejectWithValue }) => {
    try {
      const result = await axios.post(`/category/delete?id=${id}`);
      return result;
    } catch (err) {
      return rejectWithValue(err.data.status);
    }
  }
);

export const categoryPosterReducer = createSlice({
  name: "categoryPoster",
  initialState,
  reducers: {
    handlechangeShowFilter: (state, action) => {
      state.showFilter = action.payload;
    },
    handlechangeQuery: (state, action) => {
      state.query = action.payload;
    },
    handlechangePageSize: (state, action) => {
      state.pageSize = { ...state.pageSize, ...action.payload } || {};
    },
    handleShowModalAdd: (state, action) => {
      state.showModalAdd = action.payload;
    },
    handleShowModalUpd: (state, action) => {
      state.showModalUpd = { ...state.showModalUpd, ...action.payload } || {};
    },
    handleChangeStatusDel: (state, action) => {
      state.statusDel = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
        .addCase(getDataCategoryPoster.pending, (state) => {
          state.loading = true;
        })
        .addCase(getDataCategoryPoster.fulfilled, (state, action) => {
          state.loading = false;
          state.totalSize = action.payload.data.totalSize;
          state.dataCategoryPoster = action.payload.data.data;
        })
        .addCase(getDataCategoryPoster.rejected, (state) => {
          state.loading = false;
        })
        //getbyid
        .addCase(getDataCategoryPosterById.pending, (state) => {
          state.loading = true;
        })
        .addCase(getDataCategoryPosterById.fulfilled, (state, action) => {
          state.loading = false;
          state.dataCategoryPosterById = action.payload.data.data;
        })
        .addCase(getDataCategoryPosterById.rejected, (state) => {
          state.loading = false;
        })
        //sreach
        .addCase(sreachDataCategoryPoster.pending, (state) => {
          state.loading = true;
        })
        .addCase(sreachDataCategoryPoster.fulfilled, (state, action) => {
          state.loading = false;
          state.totalSize = action.payload.data.totalSize;
          state.dataCategoryPoster = action.payload.data.data;
        })
        .addCase(sreachDataCategoryPoster.rejected, (state) => {
          state.loading = false;
        });
  },
});

export const {
  handlechangeShowFilter,
  handlechangeQuery,
  handlechangePageSize,
  handleShowModalAdd,
  handleShowModalUpd,
  handleChangeStatusDel,
} = categoryPosterReducer.actions;

export const selectShowFilter = (state) => state.categoryPosterReducer.showFilter;
export const selectQuery = (state) => state.categoryPosterReducer.query;
export const selectShowModalAdd = (state) => state.categoryPosterReducer.showModalAdd;
export const selectShowModalUpd = (state) => state.categoryPosterReducer.showModalUpd;
export const selectPageSize = (state) => state.categoryPosterReducer.pageSize;

export const selectTotalSize = (state) => state.categoryPosterReducer.totalSize;
export const selectDataCategoryPosterById = (state) =>
    state.categoryPosterReducer.dataCategoryPosterById;
export const selectDataCategoryPoster = (state) => state.categoryPosterReducer.dataCategoryPoster;

export const selectStatusDel = (state) => state.categoryPosterReducer.statusDel;

export default categoryPosterReducer.reducer;
