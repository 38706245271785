import React, {memo, useEffect, useState} from "react";
import { useDispatch, useSelector } from "react-redux";
import {Form, Input, Modal, Select, notification} from "antd";
import { InputStyle, } from "../../components/Styles/InputStyles";
import { ErrorType } from "../../constant/ErrorType";
import { FormItem } from "../../components/Styles/GeneralStyles";
import {
  addCategoryPoster,
  getDataCategoryPosterById,
  handleShowModalUpd,
  selectDataCategoryPosterById,
  selectShowModalUpd,
} from "../../reducers/categoryPosterReducer";

function ModalUpd(props) {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [active, setActive] = useState(true);
  const dataSelector = useSelector(selectDataCategoryPosterById);
  const showModalUpd = useSelector(selectShowModalUpd);

  useEffect(() => {
    if (showModalUpd.id) {
      dispatch(getDataCategoryPosterById(showModalUpd.id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showModalUpd.show, showModalUpd.id]);

  useEffect(() => {
    form.setFieldsValue({
      name: dataSelector.name,
      active: dataSelector.active,
      orderFlg: dataSelector.orderFlg
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataSelector.id]);

  const onFinish = (value) => {
    const body = {
      id: showModalUpd.id,
      name: value.name,
      active: active,
      orderFlg: value.orderFlg,
    };
    dispatch(addCategoryPoster(body))
      .unwrap()
      .then((e) => {
        notification.success({
          message: `Cập nhật danh mục ${ErrorType.SAVE200}`,
        });
        handleCancelUpd();
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((er) => {
        notification.error({
          message: `Cập nhật danh mục ${ErrorType.ERSAVE400} ${er.message} ${er.statusCode}`,
        });
      });
  };
  const handleChangeActive = (value) => setActive(value);
  const handleCancelUpd = () => {
    form.setFieldsValue({
      name: "",
      active: false,
      orderFlg: 1,
    });
    dispatch(handleShowModalUpd({ show: false, id: null }));
  };

  return (
    <Modal
      forceRender
      title={`Thông tin danh mục poster`}
      open={showModalUpd.show}
      onCancel={handleCancelUpd}
      okButtonProps={{
        form: "updCategoryPoster",
        key: "submit",
        htmlType: "submit",
      }}
    >
      <Form
        form={form}
        id="updCategoryPoster"
        onFinish={onFinish}
        autoComplete="off"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
      >
        <FormItem
          label="Tên danh mục"
          name="name"
          rules={[{ required: true, message: "Nhập tên danh mục" }]}
        >
          <InputStyle placeholder="Tên danh mục" />
        </FormItem>
        <FormItem label="Thứ tự hiển thị" name="orderFlg">
          <Input type="number" placeholder="1" />
        </FormItem>
        <FormItem label="Trạng thái View">
          <Select
              onChange={handleChangeActive}
              placeholder="Chọn trạng thái view"
              style={{width: "100%"}}
              value={active ? "Hiện" : "Ẩn"}
              options={[
                {value: true, label: "Hiện"},
                {value: false, label: "Ẩn"},
              ]}
          />
        </FormItem>

      </Form>
    </Modal>
  );
}

export default memo(ModalUpd);
