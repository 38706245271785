import {Col, Table} from "antd";
import React, {memo} from "react";
import {RowStyle} from "../../components/Styles/GeneralStyles";
import moment from "moment";
import {useDispatch, useSelector} from "react-redux";
import {
    handlechangePageSize,
    selectDataHistoryDowload,
    selectPageSize,
    selectTotalSize,
} from "../../reducers/historyDowloadReducer";
import formatMoney from "../../constant/ConvertMoney";

function TableDowload() {
    const dispatch = useDispatch();
    const dataHistoryDowload = useSelector(selectDataHistoryDowload);
    const sizeSelector = useSelector(selectTotalSize);
    const pageSize = useSelector(selectPageSize);

    const FormatMoney = (value) => {
        let data = parseInt(value);
        return formatMoney(data);
    };

    const dataTable = dataHistoryDowload?.map((e) => ({
        key: e.id,
        id: e.id,
        userName: e.userName,
        createDate: e.createDate,
        email: e.email,
        phone: e.phone,
        price: e.price,
        address: e.address,
        products: e.products,
        paymentType: e.paymentType,
        note: e.note,
    }));

    const onChangePageSize = (page, size) => {
        dispatch(
            handlechangePageSize({
                page: page - 1,
                size: size,
            })
        );
    };

    const columns = [
        {
            title: "STT",
            dataIndex: "stt",
            render: (t, r, i) => i + 1,
            width: "50px",
        },
        {
            title: "Tên khách hàng",
            dataIndex: "userName",
            key: "userName",
            width: "250px",
            render: (value) => (
                <div style={{whiteSpace: 'normal', wordWrap: 'break-word'}}>
                    {value}
                </div>
            ),
        },
        {
            title: "Thời gian đặt",
            dataIndex: "createDate",
            key: "createDate",
            width: "180px",
            render: (value) => (
                <div style={{whiteSpace: 'normal', wordWrap: 'break-word'}}>
                    {moment(value).format("DD/MM/YYYY - HH:mm")}
                </div>
            ),
        },
        {
            title: "Email",
            dataIndex: "email",
            key: "email",
            width: "160px",
            render: (value) => (
                <div style={{whiteSpace: 'normal', wordWrap: 'break-word'}}>
                    {value}
                </div>
            ),
        },
        {
            title: "Số điện thoại",
            dataIndex: "phone",
            key: "phone",
            width: "120px",
            render: (value) => (
                <div style={{whiteSpace: 'normal', wordWrap: 'break-word'}}>
                    {value}
                </div>
            ),
        },
        {
            title: "Địa chỉ",
            dataIndex: "address",
            key: "address",
            width: "220px",
            render: (value) => (
                <div style={{whiteSpace: 'normal', wordWrap: 'break-word'}}>
                    {value}
                </div>
            )
        },
        {
            title: "Đơn hàng",
            dataIndex: "products",
            key: "products",
            width: "400px",
            render: (products) => (
                <table style={{width: '100%', borderCollapse: 'collapse'}}>
                    <thead>
                    <tr>
                        <th style={{border: '1px solid #ddd', textAlign: 'left'}}>Tên sản phẩm</th>
                        <th style={{border: '1px solid #ddd', textAlign: 'left'}}>Số lượng</th>
                    </tr>
                    </thead>
                    <tbody>
                    {products.map((product) => (
                        <tr key={product.id}>
                            <td style={{border: '1px solid #ddd'}}>{product.productName}</td>
                            <td style={{border: '1px solid #ddd', textAlign: 'center'}}>{product.quantity}</td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            ),
        },
        {
            title: "Giá",
            dataIndex: "price",
            key: "price",
            width: "150px",
            render: (value) => (
                <div style={{whiteSpace: 'normal', wordWrap: 'break-word'}}>
                    {FormatMoney(value)} VNĐ
                </div>
            ),
        },
        {
            title: "Ghi chú",
            dataIndex: "note",
            key: "note",
            width: "200px",
            render: (value) => (
                <div style={{whiteSpace: 'normal', wordWrap: 'break-word'}}>
                    {value}
                </div>
            ),
        },
        {
            title: "Hình thức thanh toán",
            dataIndex: "paymentType",
            key: "paymentType",
            width: "200px",
            render: (value) => (
                <div style={{whiteSpace: 'normal', wordWrap: 'break-word'}}>
                    {value}
                </div>
            ),
        },
    ];

    return (
        <RowStyle span={24}>
            <Col span={24}>
                <Table
                    columns={columns}
                    dataSource={dataTable}
                    size="small"
                    bordered={true}
                    pagination={{
                        onChange: onChangePageSize,
                        current: pageSize.page + 1,
                        pageSize: pageSize?.size,
                        total: sizeSelector,
                        showSizeChanger: true,
                        showTotal: (total) => <div>{`Tổng bản ghi: ${total}`}</div>,
                    }}
                    scroll={{x: "calc(350px + 50%)"}}
                />
            </Col>
        </RowStyle>
    );
}

export default memo(TableDowload);
