import { Input, InputNumber } from "antd";
import styled from 'styled-components';

const { TextArea } = Input;

const InputStyle = styled(Input)`
  border-color: #B3B3B3;
`;

const TextAreaStyle = styled(TextArea)`
  border-color: #B3B3B3;
`;

const InputNumberStyle = styled(InputNumber)`
  border-color: #B3B3B3;
  width: 100%;
`;

export { InputStyle, TextAreaStyle, InputNumberStyle };