import styled from 'styled-components';

const StatusStyles = styled.span`
  border-radius: 5px;
  font-weight: bold;
  padding: 5px;
  color: ${props => props.dataStatus ? "#4365B3" : "#E66D45"};
  background-color: ${props => props.dataStatus ? "#B3D6E5" : "#fff1e6"};
  border: ${props => props.dataStatus ? "#B3D6E5" : "#fff1e6"};
`;

export { StatusStyles };