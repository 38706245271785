import React, {memo, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Form, Input, Modal, notification, Select} from "antd";
import {InputStyle} from "../../components/Styles/InputStyles";
import {ErrorType} from "../../constant/ErrorType";
import {FormItem} from "../../components/Styles/GeneralStyles";
import {
    addCategoryPoster,
    handleShowModalAdd,
    selectShowModalAdd,
} from "../../reducers/categoryPosterReducer";

function ModalAdd() {
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const showModalAdd = useSelector(selectShowModalAdd);
    const [active, setActive] = useState(true);

    const onFinish = (value) => {
        const body = {
            name: value.name,
            orderFlg: value.orderFlg,
            active: active,
        };
        dispatch(addCategoryPoster(body))
            .unwrap()
            .then((e) => {
                notification.success({
                    message: `Thêm danh mục ${ErrorType.SAVE200}`,
                });
                handleCancelAdd();
                setTimeout(() => {
                    window.location.reload();
                }, 1000);
            })
            .catch((er) => {
                notification.error({
                    message: `Thêm danh mục ${ErrorType.ERSAVE400} ${er.message} ${er.statusCode}`,
                });
            });
    };
    const handleChangeActive = (value) => setActive(value);

    const handleCancelAdd = () => {
        form.setFieldsValue({
            name: "",
            orderFlg: "",
        });
        setActive(false)
        dispatch(handleShowModalAdd(false));
    };

    return (
        <Modal
            forceRender
            title={`Thêm danh mục poster`}
            open={showModalAdd}
            onCancel={handleCancelAdd}
            okButtonProps={{
                form: "addCategoryPoster",
                key: "submit",
                htmlType: "submit",
            }}
        >
            <Form
                form={form}
                id="addCategoryPoster"
                onFinish={onFinish}
                autoComplete="off"
                labelCol={{span: 24}}
                wrapperCol={{span: 24}}
            >
                <FormItem
                    label="Tên danh mục"
                    name="name"
                    rules={[{required: true, message: "Nhập tên danh mục"}]}
                >
                    <InputStyle placeholder="Tên danh mục"/>
                </FormItem>

                <FormItem label="Thứ tự hiển thị" name="orderFlg">
                    <Input type="number" placeholder="1"/>
                </FormItem>
                <FormItem label="Trạng thái View">
                    <Select
                        onChange={handleChangeActive}
                        placeholder="Chọn trạng thái view"
                        style={{width: "100%"}}
                        value={active ? "Hiện" : "Ẩn"}
                        options={[
                            {value: true, label: "Hiện"},
                            {value: false, label: "Ẩn"},
                        ]}
                    />
                </FormItem>


            </Form>
        </Modal>
    );
}

export default memo(ModalAdd);
