import React, { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {Form, Modal, Upload, notification, Col, Select} from "antd";
import { ErrorType } from "../../constant/ErrorType";
import {FormItem, RowStyle} from "../../components/Styles/GeneralStyles";
import { PlusOutlined, LoadingOutlined } from "@ant-design/icons";
import { getDataImageById, insDataImage, selectDataImageById } from "../../reducers/imageReducer";
const { Dragger } = Upload;
function ModalUpd(props) {
  const dispatch = useDispatch();
  const { show, handleCancel, id } = props;
  const [form] = Form.useForm();
  const [imageUrl, setImageUrl] = useState();
  const [loading, setLoading] = useState(false);
  const [type, setType] = useState();

  const token = localStorage.getItem("token");
  const dataSelector = useSelector(selectDataImageById);
  const jwtHeader = {
    Authorization: `Bearer ${token}`,
  };
  const dataType = [
    {"id": 1, "name": "background"},
    {"id": 2, "name": "header"},
    {"id": 3, "name": "top-cate-1"},
    {"id": 4, "name": "top-cate-2"},
    {"id": 5, "name": "top-cate-3"},
  ];
  const handleChangeType = (label) => {
    setType(label);
  };

  useEffect(() => {
    if (id) {
      dispatch(getDataImageById(id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show, id]);

  useEffect(() => {
    setImageUrl(dataSelector.image);
    setType(dataSelector.type);
  }, [dataSelector]);

  const handleChangeImage = (info) => {
    if (info.file.status === "uploading") {
      setLoading(true);
      return;
    }
    if (info.file.status === "done") {
      const url = info.file.response.path;
      setLoading(false);
      setImageUrl(url);
    }
  };

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </div>
  );

  const onFinish = () => {
    const body = {
      id: id,
      image: imageUrl,
      type: type,
    };
    dispatch(insDataImage(body))
      .unwrap()
      .then((e) => {
        notification.success({
          message: `Upload ảnh ${ErrorType.SAVE200}`,
        });
        handleCancelAdd();
      })
      .catch((er) => {
        notification.error({
          message: `Upload ảnh ${ErrorType.ERSAVE400} ${er.message} ${er.statusCode}`,
        });
      });
  };

  const handleCancelAdd = () => {
    handleCancel();
    setImageUrl("");
  };

  return (
    <Modal
      forceRender
      title={`Upload ảnh`}
      open={show}
      onCancel={handleCancelAdd}
      okButtonProps={{
        form: "UploadImgU",
        key: "submit",
        htmlType: "submit",
      }}
    >
      <Form form={form} id="UploadImgU" onFinish={onFinish} autoComplete="off">
        <FormItem>
          <Dragger
            name="file"
            listType="picture-card"
            className="avatar-uploader"
            showUploadList={false}
            action={`${process.env.REACT_APP_API_KEY}/product/upload-image`}
            headers={jwtHeader}
            method="POST"
            onChange={handleChangeImage}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
              }}
            >
              {imageUrl ? (
                <img
                  src={`${process.env.REACT_APP_API_KEY}/${imageUrl}`}
                  alt="avatar"
                  style={{
                    width: "100%",
                  }}
                />
              ) : (
                uploadButton
              )}
            </div>
          </Dragger>
        </FormItem>
        <RowStyle span={24}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
            <Form
                form={form}
                onFinish={onFinish}
                labelCol={{span: 24}}
                wrapperCol={{span: 24}}
            >
              <FormItem
                  label="Chọn vị trí hiển thị"
                  rules={[{required: true, message: "Background"}]}
              >
                <Select
                    onChange={handleChangeType}
                    placeholder="Chọn vị trí"
                    style={{width: "100%"}}
                    value={type}
                    options={dataType.map(option => ({
                      value: option.id,
                      label: option.name,
                    }))}
                />
              </FormItem>
            </Form>
          </Col>
        </RowStyle>
      </Form>
    </Modal>
  );
}
export default memo(ModalUpd);
