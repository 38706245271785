import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Row, Form, Breadcrumb, notification } from "antd";
import { Title, FormItem, ButtonSave } from "./styles";
import { HomeOutlined } from "@ant-design/icons";
import { ErrorType } from "../../constant/ErrorType";
import {
  addSupplier,
  getDataSupplier,
  selectDataSupplier,
  selectDataSupplierId,
} from "../../reducers/supplierReducer";
import { InputStyle, TextAreaStyle } from "../../components/Styles/InputStyles";
import { RowStyle } from "../../components/Styles/GeneralStyles";

export default function Supplier() {
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const dataSupplier = useSelector(selectDataSupplier);
  const supplierId = useSelector(selectDataSupplierId);

  useEffect(() => {
    dispatch(getDataSupplier());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    form.setFieldsValue(dataSupplier);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataSupplier]);

  const onSave = (value) => {
    const body = {
      id: !supplierId ? 0 : 1,
      logo: "",
      name: value.name,
      description: value.description,
      phone: value.phone,
      email: value.email,
      address: value.address,
    };
    dispatch(addSupplier(body))
      .unwrap()
      .then((e) => {
        notification.success({
          message: `Lưu thông tin ${ErrorType.SAVE200}`,
        });
      })
      .catch((er) => {
        notification.error({
          message: `Lưu thông tin ${ErrorType.ERSAVE400} ${er.message} ${er.statusCode}`,
        });
      });
  };

  const link = [
    {
      title: (
        <Title>
          {" "}
          <b>
            {" "}
            <HomeOutlined /> Thông tin liên hệ
          </b>{" "}
        </Title>
      ),
    },
  ];

  return (
    <>
      <Row span={24}>
        <Breadcrumb items={link} />
      </Row>

      <RowStyle span={24}>
        <Col xs={0} sm={0} md={1} lg={2} xl={2} xxl={2} />
        <Col xs={24} sm={24} md={22} lg={18} xl={18} xxl={18}>
          <Form
            form={form}
            onFinish={onSave}
            autoComplete="off"
            labelCol={{ xs: 24, sm: 11, md: 8, lg: 6, xl: 6, xxl: 4 }}
            wrapperCol={{ xs: 24, sm: 13, md: 16, lg: 18, xl: 18, xxl: 20 }}
          >
            <Row span={24}>
              <Col span={24}>
                <FormItem
                  label="Tên nhà cung cấp"
                  name="name"
                  rules={[{ required: true, message: "Nhập tên nhà cung cấp" }]}
                >
                  <InputStyle placeholder="Tên nhà cung cấp" />
                </FormItem>

                <FormItem
                  label="Số điện thoại"
                  name="phone"
                  rules={[{ required: true, message: "Nhập số điện thoại" }]}
                >
                  <InputStyle placeholder="Số điện thoại" />
                </FormItem>

                <FormItem
                  label="Email"
                  name="email"
                  rules={[{ required: true, message: "Nhập Email" }]}
                >
                  <InputStyle placeholder="Email" />
                </FormItem>

                <FormItem
                  label="Địa chỉ"
                  name="address"
                  rules={[{ required: true, message: "Nhập địa chỉ" }]}
                >
                  <TextAreaStyle autoSize placeholder="Địa chỉ" />
                </FormItem>

                <FormItem label="Mô tả" name="description">
                  <TextAreaStyle autoSize placeholder="Mô tả" />
                </FormItem>
              </Col>

              <Col span={24}>
                <ButtonSave type="primary" htmlType="submit">
                  Lưu thông tin
                </ButtonSave>
              </Col>
            </Row>
          </Form>
        </Col>
        <Col xs={0} sm={0} md={1} lg={4} xl={4} xxl={4} />
      </RowStyle>
    </>
  );
}
