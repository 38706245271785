import React, {memo, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
    handlechangePageSize,
    selectPageSize,
    selectTotalSize,
} from "../../reducers/productReducer";
import {Col, Space, Table, Tooltip} from "antd";
import {useNavigate} from "react-router-dom";
import {EditOutlined} from "@ant-design/icons";
import {
    PopoverEdit,
} from "../../components/Styles/PopoverStyles";
import {RowStyle} from "../../components/Styles/GeneralStyles";
import {getBrand, selectDataBrand} from "../../reducers/brandReducer";


function TableProduct() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const sizeSelector = useSelector(selectTotalSize);
    const pageSize = useSelector(selectPageSize);
    const dataProduct = useSelector(selectDataBrand);

    useEffect(() => {
        onGetData();
        // eslint-disable-next-line
    }, []);

    const onGetData = () => {
        dispatch(getBrand());
    };

    const dataTable = dataProduct?.map((e) => ({
        key: e.id,
        id: e.id,
        name: e.name,
        active: e.active,
    }));

    const onChangePageSize = (page, size) => {
        dispatch(
            handlechangePageSize({
                page: page - 1,
                size: size,
            })
        );
    };

    const onShowModalUpd = (record) => {
        navigate(`/updBrand/${record?.id}`, {state: {id: record?.id}});
    };
    const columns = [
        {
            title: "STT",
            dataIndex: "stt",
            key: "stt",
            render: (t, r, i) => i + 1,
            width: "50px",
            ellipsis: {
                showTitle: false,
            },
        },
        {
            title: "Thương hiệu",
            dataIndex: "name",
            key: "name",
            width: "220px",
            ellipsis: {showTitle: false},
            render: (value) => (
                <Tooltip placement="topLeft" title={value}>
                    {value}
                </Tooltip>
            ),
        },
        {
            title: "Chức năng",
            key: "action",
            fixed: "right",
            width: "20px",
            render: (record) => (
                <Space>
                    <PopoverEdit content="Chi tiết và chỉnh sửa thông tin">
                        <EditOutlined onClick={() => onShowModalUpd(record)}/>
                    </PopoverEdit>
                </Space>
            ),
        },
    ];

    return (
        <RowStyle span={24}>
            <Col span={24}>
                <Table
                    columns={columns}
                    dataSource={dataTable}
                    size="small"
                    pagination={{
                        onChange: onChangePageSize,
                        current: pageSize.page + 1,
                        pageSize: pageSize?.size,
                        total: sizeSelector,
                        showSizeChanger: true,
                        showTotal: (total) => <div>{`Tổng bản ghi: ${total}`}</div>,
                    }}
                    scroll={{x: "calc(350px + 50%)"}}
                />
            </Col>
        </RowStyle>
    );
}

export default memo(TableProduct);
