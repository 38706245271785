import axios from "../services/Api";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const initialState = {
    dataBrand: [],
    dataBrandById: [],
    loading: false,
};

export const getBrand = createAsyncThunk(
    "get/DataBrand",
    async (param) => {
        const result = await axios.get(
            `/brand/get-all`
        );
        return result;
    }
);
export const insDataBrand = createAsyncThunk(
    "ins/DataBrand",
    async (body, { rejectWithValue }) => {
        try {
            const result = await axios.post("/brand/save", body);
            return result;
        } catch (err) {
            return rejectWithValue(err.data.status);
        }
    }
);

export const getDataBrandById = createAsyncThunk(
    "byId/getDataBrandById",
    async (id) => {
        const result = await axios.get(`/brand/get-id?id=${id}`);
        return result;
    }
);

export const searchDataBrand = createAsyncThunk(
    "search/DataBrand",
    async (param) => {
        const result = await axios.get(
            // `/product/search?page=${param.page}&size=${param.size}&query=${param.query}`
            `/product/get-all-product?page=${param.page}&size=${param.size}&query=${param.query}`
        );
        return result;
    }
);
export const brandReducer = createSlice({
    name: "otherBrand",
    initialState,
    reducers: {
        handlechangeShowFilter: (state, action) => {
            state.showFilter = action.payload;
        },
        handlechangeQuery: (state, action) => {
            state.query = action.payload;
        },
        handlechangePageSize: (state, action) => {
            state.pageSize = { ...state.pageSize, ...action.payload } || {};
        },

        handleChangeStatusDel: (state, action) => {
            state.statusDel = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getBrand.pending, (state) => {
                state.loading = true;
            })
            .addCase(getBrand.fulfilled, (state, action) => {
                state.loading = false;
                state.totalSize = action.payload.data.totalSize;
                state.dataBrand = action.payload.data.data;
            })
            .addCase(getBrand.rejected, (state) => {
                state.loading = false;
            })
            .addCase(getDataBrandById.pending, (state) => {
                state.loading = true;
            })
            .addCase(getDataBrandById.fulfilled, (state, action) => {
                state.loading = false;
                state.dataBrandById = action.payload.data.data;
            })
            .addCase(getDataBrandById.rejected, (state) => {
                state.loading = false;
            });
    },
});
export const {
    handlechangeShowFilter,
    handlechangeQuery,
    handlechangePageSize,

    handleChangeStatusDel,
} = brandReducer.actions;

export const selectDataBrand = (state) =>
    state.brandReducer.dataBrand;
export const selectDataBrandById = (state) =>
    state.brandReducer.dataBrandById;
export default brandReducer.reducer;
