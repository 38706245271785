import axios from "../services/Api";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const initialState = {
    dataImage: [],
    dataImageById: [],
    loading: false,
};

export const getImage = createAsyncThunk(
    "get/DataImage",
    async (param) => {
        const result = await axios.get(
            `/comboImages/get-all`
        );
        return result;
    }
);
export const insDataImage = createAsyncThunk(
    "ins/DataImage",
    async (body, { rejectWithValue }) => {
        try {
            const result = await axios.post("/comboImages/save", body);
            return result;
        } catch (err) {
            return rejectWithValue(err.data.status);
        }
    }
);

export const getDataImageById = createAsyncThunk(
    "byId/getDataImageById",
    async (id) => {
        const result = await axios.get(`/comboImages/get-id?id=${id}`);
        return result;
    }
);

export const deleteDataImageById = createAsyncThunk(
    "byId/postDeleteId",
    async (id) => {
        const result = await axios.post(`/comboImages/delete?id=${id}`);
        return result;
    }
);

export const searchDataImage = createAsyncThunk(
    "search/DataImage",
    async (param) => {
        const result = await axios.get(
            // `/product/search?page=${param.page}&size=${param.size}&query=${param.query}`
            `/product/get-all-product?page=${param.page}&size=${param.size}&query=${param.query}`
        );
        return result;
    }
);

export const imageReducer = createSlice({
    name: "otherImage",
    initialState,
    reducers: {
        handlechangeShowFilter: (state, action) => {
            state.showFilter = action.payload;
        },
        handlechangeQuery: (state, action) => {
            state.query = action.payload;
        },
        handlechangePageSize: (state, action) => {
            state.pageSize = { ...state.pageSize, ...action.payload } || {};
        },

        handleChangeStatusDel: (state, action) => {
            state.statusDel = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getImage.pending, (state) => {
                state.loading = true;
            })
            .addCase(getImage.fulfilled, (state, action) => {
                state.loading = false;
                state.totalSize = action.payload.data.totalSize;
                state.dataImage = action.payload.data.data;
            })
            .addCase(getImage.rejected, (state) => {
                state.loading = false;
            })
            .addCase(getDataImageById.pending, (state) => {
                state.loading = true;
            })
            .addCase(getDataImageById.fulfilled, (state, action) => {
                state.loading = false;
                state.dataImageById = action.payload.data.data;
            })
            .addCase(getDataImageById.rejected, (state) => {
                state.loading = false;
            });
    },
});
export const {
    handlechangeShowFilter,
    handlechangeQuery,
    handlechangePageSize,

    handleChangeStatusDel,
} = imageReducer.actions;

export const selectDataImage = (state) =>
    state.imageReducer.dataImage;
export const selectDataImageById = (state) =>
    state.imageReducer.dataImageById;
export default imageReducer.reducer;
