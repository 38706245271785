import axios from "../services/Api";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const initialState = {
  dataProductById: {},
  dataProduct: [],
  totalSize: null,

  showFilter: false,
  query: "",
  pageSize: {
    page: 0,
    size: 10,
  },
  statusDel: "",
};

export const getDataProduct = createAsyncThunk(
  "get/DataProduct",
  async (param) => {
    const result = await axios.get(
      `/product/get-all-product?page=${param.page}&size=${param.size}`
    );
    return result;
  }
);

export const getDataProductHot = createAsyncThunk(
  "get/DataProductHot",
  async () => {
    const result = await axios.get(
      `/product/get-product-filter-hot`
    );
    return result;
  }
);

export const searchDataProduct = createAsyncThunk(
  "search/DataProduct",
  async (param) => {
    const result = await axios.get(
      // `/product/search?page=${param.page}&size=${param.size}&query=${param.query}`
        `/product/get-all-product?page=${param.page}&size=${param.size}&query=${param.query}`
    );
    return result;
  }
);

export const getDataProductById = createAsyncThunk(
  "byId/getDataProductById",
  async (id) => {
    const result = await axios.get(`/product/get-id?id=${id}`);
    return result;
  }
);

export const insDataProduct = createAsyncThunk(
  "ins/DataProduct",
  async (body, { rejectWithValue }) => {
    try {
      const result = await axios.post("/product/save", body);
      return result;
    } catch (err) {
      return rejectWithValue(err.data.status);
    }
  }
);

export const delDataProduct = createAsyncThunk(
  "del/DataProduct",
  async (id, { rejectWithValue }) => {
    try {
      const result = await axios.post(`/product/delete?id=${id}`);
      return result;
    } catch (err) {
      return rejectWithValue(err.data.status);
    }
  }
);

export const productReducer = createSlice({
  name: "otherProduct",
  initialState,
  reducers: {
    handlechangeShowFilter: (state, action) => {
      state.showFilter = action.payload;
    },
    handlechangeQuery: (state, action) => {
      state.query = action.payload;
    },
    handlechangePageSize: (state, action) => {
      state.pageSize = { ...state.pageSize, ...action.payload } || {};
    },

    handleChangeStatusDel: (state, action) => {
      state.statusDel = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
        // getDataProduct
        .addCase(getDataProduct.pending, (state) => {
          state.loading = true;
        })
        .addCase(getDataProduct.fulfilled, (state, action) => {
          state.loading = false;
          state.totalSize = action.payload.data.totalSize;
          state.dataProduct = action.payload.data.data;
        })
        .addCase(getDataProduct.rejected, (state) => {
          state.loading = false;
        })

        // searchDataProduct
        .addCase(searchDataProduct.pending, (state) => {
          state.loading = true;
        })
        .addCase(searchDataProduct.fulfilled, (state, action) => {
          state.loading = false;
          state.totalSize = action.payload.data.totalSize;
          state.dataProduct = action.payload.data.data;
        })
        .addCase(searchDataProduct.rejected, (state) => {
          state.loading = false;
        })

        // getDataProductById
        .addCase(getDataProductById.pending, (state) => {
          state.loading = true;
        })
        .addCase(getDataProductById.fulfilled, (state, action) => {
          state.loading = false;
          state.dataProductById = action.payload.data.data;
        })
        .addCase(getDataProductById.rejected, (state) => {
          state.loading = false;
        });
  },
});

export const {
  handlechangeShowFilter,
  handlechangeQuery,
  handlechangePageSize,

  handleChangeStatusDel,
} = productReducer.actions;

export const selectShowFilter = (state) => state.productReducer.showFilter;
export const selectQuery = (state) => state.productReducer.query;

export const selectPageSize = (state) => state.productReducer.pageSize;
export const selectStatusDel = (state) => state.productReducer.statusDel;

export const selectTotalSize = (state) => state.productReducer.totalSize;
export const selectDataProductById = (state) =>
  state.productReducer.dataProductById;
export const selectDataProduct = (state) => state.productReducer.dataProduct;

export default productReducer.reducer;
