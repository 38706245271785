import React, {memo, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Breadcrumb, Col, Form, Row, Select, Upload, notification} from "antd";
import {PlusOutlined, LoadingOutlined, HomeOutlined} from "@ant-design/icons";
import {TextAreaStyle} from "../../components/Styles/InputStyles";
import {ErrorType} from "../../constant/ErrorType";
import {RowStyle, Title} from "../../components/Styles/GeneralStyles";
import {FormItem} from "../../components/Styles/GeneralStyles";
import {
    getDataBlogById, insDataBlog,
    selectDataBlogById,
} from "../../reducers/blogReducer";
import {Link, useNavigate} from "react-router-dom";
import {CKEditor} from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import ButtonAdd from "../../components/ButtonCustom/ButtonAdd";

function UpdBlog() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const token = localStorage.getItem("token");
    const id = window.location.href.split("updBlog/")[1];
    const [form] = Form.useForm();
    const dataSelector = useSelector(selectDataBlogById);
    const [loading, setLoading] = useState(false);
    const [imageUrl, setImageUrl] = useState();
    const [content, setContent] = useState("");
    const [active, setActive] = useState(true);
    const jwtHeader = {
        Authorization: `Bearer ${token}`,
    };

    useEffect(() => {
        if (id) {
            dispatch(getDataBlogById(id));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch]);

    useEffect(() => {
        form.setFieldsValue({
            title: dataSelector.title,
        });
        setContent(dataSelector.content ? dataSelector.content : "");
        setImageUrl(dataSelector.image);
        setActive(dataSelector.active);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataSelector]);


    const handleChangeActive = (value) => {
        setActive(value);
    };

    const onFinish = (value) => {
        const body = {
            id: id,
            name: value.name,
            title: value.title,
            content: content,
            image: imageUrl,
            active: active,
        };
        dispatch(insDataBlog(body))
            .unwrap()
            .then((e) => {
                notification.success({
                    message: `Cập nhật bài viết ${ErrorType.SAVE200}`,
                });
                handleCancelUpd();
            })
            .catch((er) => {
                notification.error({
                    message: `Cập nhật bài viết ${ErrorType.ERSAVE400} ${er.message} ${er.statusCode}`,
                });
            });
    };

    const handleCancelUpd = () => {
        form.setFieldsValue({
            name: "",
            title: "",
        });
        setContent("");
        setImageUrl();
        setLoading(false);
        setActive(true);
        navigate("/blog");
    };

    const handleChangeImage = (info) => {
        if (info.file.status === "uploading") {
            setLoading(true);
            return;
        }
        if (info.file.status === "done") {
            const url = info.file.response.path;
            setLoading(false);
            setImageUrl(url);
        }
    };

    const API_URL = `${process.env.REACT_APP_API_KEY}`;
    const API_URL_HTTPS = 'https://maylocnuocg7.com:8088';
    const UPLOAD_ENDPOINT = `product/upload-image`;

    function uploadAdapter(loader) {
        return {
            upload: () =>
                new Promise((resolve, reject) => {
                    const body = new FormData();
                    loader.file.then((file) => {
                        body.append("file", file);
                        fetch(`${API_URL}/${UPLOAD_ENDPOINT}`, {
                            method: "post",
                            body: body,
                        })
                            .then((res) => res.json())
                            .then((res) => {
                                resolve({
                                    default: `${API_URL_HTTPS}/${res.path}`,
                                });
                            })
                            .catch(reject);
                    });
                }),
        };
    }

    function uploadPlugin(editor) {
        editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
            return uploadAdapter(loader);
        };
    }

    const uploadButton = (
        <div>
            {loading ? <LoadingOutlined/> : <PlusOutlined/>}
            <div
                style={{
                    marginTop: 8,
                }}
            >
                Upload
            </div>
        </div>
    );

    const link = [
        {
            title: (
                <>
                    <Link to={"/"}>
                        <HomeOutlined/>
                    </Link>
                </>
            ),
        },
        {
            title: (
                <>
                    <Link to={"/blog"}>
                        <b>Quản lý Blog</b>
                    </Link>
                </>
            ),
        },
        {
            title: (
                <Title>
                    <b>Chi tiết bài viết</b>
                </Title>
            ),
        },
    ];

    return (
        <>
            <Row span={24}>
                <Breadcrumb items={link}/>
            </Row>

            <RowStyle span={24} style={{marginTop: 20, paddingLeft: 50, paddingRight: 50}}>
                <Col xs={24} sm={24} md={6} lg={6} xl={4} xxl={4}>
                    <Upload
                        name="file"
                        listType="picture-card"
                        className="avatar-uploader"
                        showUploadList={false}
                        action={`${process.env.REACT_APP_API_KEY}/blog/upload-image`}
                        headers={jwtHeader}
                        method="POST"
                        onChange={handleChangeImage}
                    >
                        {imageUrl ? (
                            <img
                                src={`${process.env.REACT_APP_API_KEY}/${imageUrl}`}
                                alt="avatar"
                                style={{
                                    width: "100%",
                                }}
                            />
                        ) : (
                            uploadButton
                        )}
                    </Upload>
                </Col>
                <Col xs={24} sm={24} md={18} lg={18} xl={20} xxl={20}>
                    <Form form={form} onFinish={onFinish} labelCol={{span: 24}} wrapperCol={{span: 24}}>
                        <FormItem
                            label="Tiêu đề bài viết"
                            name="title"
                            rules={[{required: false, message: "Nhập tiêu đề bài viết"}]}
                        >
                            <TextAreaStyle placeholder="Tiêu đề bài viết"/>
                        </FormItem>

                        <Row span={24}>
                            <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                                <FormItem label="Trạng thái View">
                                    <Select
                                        onChange={handleChangeActive}
                                        placeholder="Chọn trạng thái view"
                                        style={{width: "100%"}}
                                        value={active ? "Hiện" : "Ẩn"}
                                        options={[
                                            {value: true, label: "Hiện"},
                                            {value: false, label: "Ẩn"},
                                        ]}
                                    />
                                </FormItem>
                            </Col>
                        </Row>


                        <FormItem
                            label="Nội dung bài viết"
                            rules={[{required: false, message: "Nhập nội dung bài viết"}]}
                        >
                            <CKEditor
                                config={{
                                    extraPlugins: [uploadPlugin],
                                }}
                                editor={ClassicEditor}
                                data={content}
                                onChange={(event, editor) => {
                                    const data = editor.getData();
                                    setContent(data);
                                }}
                                onReady={(editor) => {
                                }}
                                onBlur={(event, editor) => {
                                }}
                                onFocus={(event, editor) => {
                                }}
                            />
                        </FormItem>

                        <Form.Item>
                            <ButtonAdd title="Xác nhận"/>
                        </Form.Item>
                    </Form>
                </Col>
            </RowStyle>
        </>
    );
}

export default memo(UpdBlog);
