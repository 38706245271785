import React from "react";
import { Button } from "antd";
import { PlusOutlined } from "@ant-design/icons";
export default function ButtonAdd({ onClick, title }) {
  return (
    <Button
      style={{
        backgroundColor: "rgb(55, 181, 157)",
        border: "rgb(55, 181, 157)",
        float: "right",
      }}
      type="primary"
      htmlType="submit"
      onClick={onClick}
    >
      {title ?? (
        <>
          <PlusOutlined /> Thêm
        </>
      )}
    </Button>
  );
}
