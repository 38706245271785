// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.trigger {
  padding: 0 24px;
  font-size: 18px;
  line-height: 64px;
  cursor: pointer;
  transition: color 0.3s;
}

.trigger:hover {
  color: #1890ff;
}

.logo {
  height: 40px;
  margin: 16px;
  background: #bae0ff;
}

.site-layout .site-layout-background {
  background: #fff;
}`, "",{"version":3,"sources":["webpack://./src/screens/DefaultLayout/DefaultLayout.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,eAAe;EACf,iBAAiB;EACjB,eAAe;EACf,sBAAsB;AACxB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,YAAY;EACZ,YAAY;EACZ,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":[".trigger {\r\n  padding: 0 24px;\r\n  font-size: 18px;\r\n  line-height: 64px;\r\n  cursor: pointer;\r\n  transition: color 0.3s;\r\n}\r\n\r\n.trigger:hover {\r\n  color: #1890ff;\r\n}\r\n\r\n.logo {\r\n  height: 40px;\r\n  margin: 16px;\r\n  background: #bae0ff;\r\n}\r\n\r\n.site-layout .site-layout-background {\r\n  background: #fff;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
