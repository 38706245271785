import React, {memo, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
    handleShowModalUpd,
    handlechangePageSize,
    selectDataCategoryPoster,
    selectPageSize,
    selectTotalSize,
    getDataCategoryPoster,
    handleShowModalAdd, addCategoryPoster,
} from "../../reducers/categoryPosterReducer";
import {EditOutlined, PlusOutlined} from "@ant-design/icons";
import {Col, Form, Input, Modal, notification, Select, Space, Table, Tooltip} from "antd";
import {ColFilter, FormItem, RowStyle} from "../../components/Styles/GeneralStyles";
import {PopoverEdit} from "../../components/Styles/PopoverStyles";
import ButtonAdd from "../../components/ButtonCustom/ButtonAdd";
import {StatusStyles} from "../../components/Styles/StatusStyles";
import {InputStyle} from "../../components/Styles/InputStyles";
import {ErrorType} from "../../constant/ErrorType";

function TableCategory() {
    const dispatch = useDispatch();
    const [isAddModalVisible, setIsAddModalVisible] = useState(false);
    const [currentCategory, setCurrentCategory] = useState(null);
    const [form] = Form.useForm();
    const [active, setActive] = useState(true);
    const dataCategorySelect = useSelector(selectDataCategoryPoster);
    const sizeSelector = useSelector(selectTotalSize);
    const pageSize = useSelector(selectPageSize);

    useEffect(() => {
        dispatch(getDataCategoryPoster());
        // eslint-disable-next-line
    }, []);


    const dataTable = dataCategorySelect?.map((e) => ({
        key: e?.id,
        id: e?.id,
        name: e?.name,
        orderFlg: e?.orderFlg,
        active: e?.active,
        children: e?.children,
    }));

    const onChangePageSize = (page, size) => {
        dispatch(handlechangePageSize({
            page: page - 1, size: size,
        }));
    };

    const onShowModalAddSub = (category) => {
        setCurrentCategory(category);
        form.resetFields();
        setIsAddModalVisible(true);
    };

    const onShowModalUpd = (record) => {
        dispatch(handleShowModalUpd({show: true, id: record.id}));
    };
    const handleCancel = () => {
        setIsAddModalVisible(false);
        setCurrentCategory(null);
    };

    const onShowModalAdd = () => {
        dispatch(handleShowModalAdd(true));
    };
    const handleAddOk = () => {
        form.validateFields().then(values => {
            const newCategory = {
                ...values, parentId: currentCategory.id, active: active
            };
            dispatch(addCategoryPoster(newCategory)).unwrap()
                .then((e) => {
                    notification.success({
                        message: `Thêm danh mục ${ErrorType.SAVE200}`,
                    });
                    handleCancel();
                    setTimeout(() => {
                        window.location.reload();
                    }, 1000);
                })
                .catch((er) => {
                    notification.error({
                        message: `Thêm danh mục ${ErrorType.ERSAVE400} ${er.message} ${er.statusCode}`,
                    });
                });
        });

    };
    const handleChangeActive = (value) => setActive(value);


    const columns = [{
        title: "STT", dataIndex: "stt", key: "stt", render: (t, r, i) => i + 1, width: "50px", ellipsis: {
            showTitle: false,
        },
    }, {
        title: "Tên danh mục",
        dataIndex: "name",
        key: "name",
        ellipsis: {showTitle: false},
        width: "250px",
        render: (value) => (<Tooltip placement="topLeft" title={value}>
            {value}
        </Tooltip>),
    }, {
        title: "Thứ tự",
        dataIndex: "orderFlg",
        key: "orderFlg",
        width: "120px",
        ellipsis: {showTitle: false},
        render: (value) => (<Tooltip>
            <b>{value}</b>
        </Tooltip>),
    }, {
        title: "Trạng thái",
        dataIndex: "active",
        key: "active",
        width: "80px",
        filters: [{text: "Hiện", value: true}, {text: "Ẩn", value: false},],
        onFilter: (value, record) => record.active === value,
        render: (value) => (<StatusStyles dataStatus={value}>
            {value === true || value === 1 ? "Hiện" : "Ẩn"}
        </StatusStyles>),
    }, {
        title: "Chức năng", key: "action", fixed: "right", width: "120px", render: (record) => (<Space>
            <PopoverEdit content="Chi tiết và chỉnh sửa thông tin">
                <EditOutlined onClick={() => onShowModalUpd(record)}/>
            </PopoverEdit>

            <PopoverEdit content="Thêm danh mục con">
                <PlusOutlined onClick={() => onShowModalAddSub(record)}/>
            </PopoverEdit>
        </Space>),
    },];

    return (<>
        <RowStyle span={24}>
            <ColFilter span={24}>
                <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                    <ButtonAdd onClick={onShowModalAdd}/>
                </Col>
            </ColFilter>
            <Col span={24}>
                <Table
                    columns={columns}
                    dataSource={dataTable}
                    size="small"
                    pagination={{
                        onChange: onChangePageSize,
                        current: pageSize.page + 1,
                        pageSize: pageSize?.size,
                        total: sizeSelector,
                        showSizeChanger: true,
                        showTotal: (total) => <div>{`Tổng bản ghi: ${total}`}</div>,
                    }}
                    scroll={{x: "calc(350px + 50%)"}}
                />
            </Col>
        </RowStyle>

        <Modal
            title="Thêm danh mục mới"
            visible={isAddModalVisible}
            onOk={handleAddOk}
            onCancel={handleCancel}
        >
            <Form form={form} initialValues={{
                orderFlg: 1000,
            }} layout="vertical">
                <FormItem
                    label="Tên danh mục"
                    name="name"
                    rules={[{required: true, message: "Nhập tên danh mục"}]}
                >
                    <InputStyle placeholder="Tên danh mục"/>
                </FormItem>

                <FormItem label="Thứ tự hiển thị" name="orderFlg">
                    <Input type="number" placeholder=""/>
                </FormItem>
                <FormItem label="Trạng thái View">
                    <Select
                        onChange={handleChangeActive}
                        placeholder="Chọn trạng thái view"
                        style={{width: "100%"}}
                        value={active ? "Hiện" : "Ẩn"}
                        options={[
                            {value: true, label: "Hiện"},
                            {value: false, label: "Ẩn"},
                        ]}
                    />
                </FormItem>

            </Form>
        </Modal>
    </>);
}

export default memo(TableCategory);
