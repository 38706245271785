import React, { memo, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  handlechangePageSize,
  handlechangeQuery,
  handlechangeShowFilter,
  selectPageSize,
  selectQuery,
  selectShowFilter,
  selectStatusDel,
} from "../../reducers/productReducer";
import { Col, Row, notification } from "antd";
import {
  ColFilter,
  RowFilter,
  RowStyle,
} from "../../components/Styles/GeneralStyles";
import Filter from "../../components/Filter/Filter";
import ButtonAdd from "../../components/ButtonCustom/ButtonAdd";
import { InputStyle } from "../../components/Styles/InputStyles";
import {
  ButtonRollBack,
  ButtonSreach,
} from "../../components/Styles/ButtonStyles";
import { SearchOutlined, ReloadOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import {getBrand, searchDataBrand} from "../../reducers/brandReducer";

function FilterBrand() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const inputRef = useRef(null);
  const showFilter = useSelector(selectShowFilter);
  const query = useSelector(selectQuery);
  const pageSize = useSelector(selectPageSize);
  const statusDel = useSelector(selectStatusDel);

  useEffect(() => {
    dispatch(
        handlechangePageSize({
          page: 0,
          size: 10,
        })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    onGetData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageSize.page, pageSize.size, statusDel]);

  const onGetData = () => {
    if (query.title !== "" || query.cateTitle !== "") {
      const params = {
        query: query,
        page: pageSize.page,
        size: pageSize.size,
      };
      dispatch(searchDataBrand(params));
    } else {
      const params = {
        page: pageSize.page,
        size: pageSize.size,
      };
      dispatch(getBrand(params));
    }
  };

  const onReloadSearch = () => {
    dispatch(handlechangeQuery(""));
    dispatch(
        handlechangePageSize({
          page: 0,
          size: 10,
        })
    );
    const params = {
      page: 0,
      size: 10,
    };
    dispatch(getBrand(params));
  };

  const onShowModalAdd = () => {
    navigate("/insBrand"); // use navigate instead of history.push
  };

  const onClickFilter = () => {
    dispatch(handlechangeShowFilter(!showFilter));
  };

  const onChangeQuery = (ev) => {
    dispatch(handlechangeQuery(ev.target.value));
  };

  const onSearch = () => {
    if (query !== "") {
      const data = {
        page: 0,
        size: 10,
        query: query,
      };
      dispatch(searchDataBrand(data));
    } else {
      notification.info({
        message: `Nhập dữ liệu cần tìm kiếm hoặc reload table`,
      });
      inputRef.current.focus();
    }
  };

  return (
      <RowStyle span={24}>
        <ColFilter span={24}>
          <Row span={24}>
            <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
              <Filter onClickFilter={onClickFilter} />
            </Col>
            <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
              <ButtonAdd onClick={onShowModalAdd} />
            </Col>
          </Row>
        </ColFilter>

        <ColFilter span={24}>
          {showFilter === true ? (
              <RowFilter>
                <Col xs={24} sm={24} md={24} lg={6} xl={6} xxl={6}>
                  <InputStyle
                      ref={inputRef}
                      value={query.title}
                      onChange={onChangeQuery}
                      style={{ width: "90%" }}
                      placeholder="Tìm kiếm theo tên"
                      onPressEnter={onSearch}
                  />
                </Col>
                <Col xs={24} sm={24} md={24} lg={6} xl={6} xxl={6}>
                  <ButtonSreach type="primary" onClick={onSearch}>
                    <SearchOutlined /> Tìm kiếm
                  </ButtonSreach>
                  <ButtonRollBack type="primary" danger onClick={onReloadSearch}>
                    <ReloadOutlined /> Tải lại
                  </ButtonRollBack>
                </Col>
                <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12} />
              </RowFilter>
          ) : null}
        </ColFilter>
      </RowStyle>
  );
}

export default memo(FilterBrand);
