import { Popconfirm, Popover } from "antd";
import styled from "styled-components";

const PopoverEdit = styled(Popover)`
  background-color: rgb(55, 181, 157);
  border-radius: 5px;
  color: #ffffff;
  padding: 5px;
  border: 1px solid rgb(55, 181, 157);
`;

const PopoverChangePass = styled(Popover)`
  background-color: #4365b3;
  border-radius: 5px;
  color: #ffffff;
  padding: 5px;
  border: 1px solid #4365b3;
`;

const PopoverScore = styled(Popover)`
  background-color: rgb(240, 65, 52);
  border-radius: 5px;
  color: #ffffff;
  padding: 5px;
  border: 1px solid rgb(240, 65, 52);
`;

const PopoverDelete = styled(Popconfirm)`
  background-color: rgb(240, 65, 52);
  border-radius: 5px;
  color: #ffffff;
  padding: 5px;
  border: 1px solid rgb(240, 65, 52);
`;

const PopoverLock = styled(Popconfirm)`
  background-color: #feaa54;
  border-radius: 5px;
  color: #ffffff;
  padding: 5px;
  border: 1px solid #feaa54;
`;

const PopoverPopular = styled(Popconfirm)`
  background-color: #7b64ff;
  border-radius: 5px;
  color: #ffffff;
  padding: 5px;
  border: 1px solid #7b64ff;
`;

const PopoverUnlock = styled(Popconfirm)`
  background-color: #4365b3;
  border-radius: 5px;
  color: #ffffff;
  padding: 5px;
  border: 1px solid #4365b3;
`;

const PopoverLoginWeb = styled(Popover)`
  background-color: #4731d3;
  border-radius: 5px;
  color: #ffffff;
  padding: 5px;
  border: 1px solid #4731d3;
`;

export {
  PopoverEdit,
  PopoverChangePass,
  PopoverScore,
  PopoverDelete,
  PopoverLock,
  PopoverUnlock,
  PopoverPopular,
  PopoverLoginWeb,
};
