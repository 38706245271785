import React, { memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getDataDowload,
  handlechangePageSize,
  handlechangeQuery,
  handlechangeShowFilter,
  selectPageSize,
  selectQuery,
  selectShowFilter,
  sreachDataDowload,
} from "../../reducers/historyDowloadReducer";
import { useEffect } from "react";
import { ColFilter, RowFilter, RowStyle } from "../../components/Styles/GeneralStyles";
import { Col, Row } from "antd";
import Filter from "../../components/Filter/Filter";
import { InputStyle } from "../../components/Styles/InputStyles";
import dayjs from "dayjs";
import { ButtonRollBack, ButtonSreach } from "../../components/Styles/ButtonStyles";
import FilterTime from "../../components/Filter/FilterTime";
import moment from "moment";
import { SearchOutlined, ReloadOutlined } from "@ant-design/icons";
function FilterDowload() {
  const dispatch = useDispatch();

  const showFilter = useSelector(selectShowFilter);
  const query = useSelector(selectQuery);
  const pageSize = useSelector(selectPageSize);

  const dateFormat = "YYYY-MM-DD";
  const today = new Date();
  const todayDate = new Date().toLocaleDateString("sv-SE");
  today.setDate(today.getDate() - 30);
  const date = new Date(today).toLocaleDateString("sv-SE");

  // Load dữ liệu lần đầu
  useEffect(() => {
    dispatch(
      handlechangePageSize({
        page: 0,
        size: 10,
      })
    );
    dispatch(
      handlechangeQuery({
        startDate: moment(date).format("YYYY-MM-DD"),
        endDate: moment(todayDate).format("YYYY-MM-DD"),
        email: "",
      })
    );
    const params = {
      startDate: moment(date).format("YYYY-MM-DD"),
      endDate: moment(todayDate).format("YYYY-MM-DD"),
      query: "",
      page: 0,
      size: 10,
    };
    dispatch(getDataDowload(params));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Load dữ liệu khi page size thay đổi
  useEffect(() => {
    if (query.startDate) {
      onGetData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageSize.page, pageSize.size]);

  // Lấy data lịch sử dowload
  const onGetData = () => {
    if (query.email !== "") {
      const params = {
        startDate: query.startDate,
        endDate: query.endDate,
        query: query.email,
        page: pageSize.page,
        size: pageSize.size,
      };
      dispatch(sreachDataDowload(params));
    } else {
      const params = {
        startDate: query.startDate,
        endDate: query.endDate,
        query: "",
        page: pageSize.page,
        size: pageSize.size,
      };
      dispatch(getDataDowload(params));
    }
  };

  const onReloadSearch = () => {
    dispatch(
      handlechangeQuery({
        startDate: moment(date).format("YYYY-MM-DD"),
        endDate: moment(todayDate).format("YYYY-MM-DD"),
        email: "",
      })
    );
    dispatch(
      handlechangePageSize({
        page: 0,
        size: 10,
      })
    );
    const params = {
      startDate: moment(date).format("YYYY-MM-DD"),
      endDate: moment(todayDate).format("YYYY-MM-DD"),
      query: "",
      page: 0,
      size: 10,
    };
    dispatch(getDataDowload(params));
  };

  const onClickFilter = () => {
    dispatch(handlechangeShowFilter(!showFilter));
  };

  const onChangeQuery = (ev) => {
    dispatch(
      handlechangeQuery({
        email: ev.target.value,
      })
    );
  };

  const onChangeTimeStart = (e, date) => {
    dispatch(
      handlechangeQuery({
        startDate: date,
      })
    );
  };

  const onChangeTimeEnd = (e, date) => {
    dispatch(
      handlechangeQuery({
        endDate: date,
      })
    );
  };

  const onSearch = () => {
    if (query.email !== "") {
      const params = {
        startDate: query.startDate,
        endDate: query.endDate,
        query: query.email,
        page: 0,
        size: 10,
      };
      dispatch(sreachDataDowload(params));
    } else {
      const params = {
        startDate: query.startDate,
        endDate: query.endDate,
        query: "",
        page: pageSize.page,
        size: pageSize.size,
      };
      dispatch(getDataDowload(params));
    }
  };

  return (
    <RowStyle span={24}>
      <ColFilter span={24}>
        <Row span={24}>
          <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
            <Filter onClickFilter={onClickFilter} />
          </Col>
        </Row>
      </ColFilter>

      <ColFilter span={24}>
        {showFilter === true ? (
          <RowFilter>
            <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
              <FilterTime
                valueStart={dayjs(query.startDate, dateFormat)}
                valueEnd={dayjs(query.endDate, dateFormat)}
                format={dateFormat}
                onChangeTimeStart={onChangeTimeStart}
                onChangeTimeEnd={onChangeTimeEnd}
              />
            </Col>
            <Col xs={24} sm={24} md={24} lg={6} xl={6} xxl={6}>
              <InputStyle
                value={query.email}
                onChange={onChangeQuery}
                style={{ width: "90%" }}
                placeholder="Tìm kiếm theo tài khoản"
                onPressEnter={onSearch}
              />
            </Col>
            <Col xs={24} sm={24} md={24} lg={6} xl={6} xxl={6}>
              <ButtonSreach type="primary" onClick={onSearch}>
                <SearchOutlined /> Tìm kiếm
              </ButtonSreach>
              <ButtonRollBack type="primary" danger onClick={onReloadSearch}>
                <ReloadOutlined /> Tải lại
              </ButtonRollBack>
            </Col>
          </RowFilter>
        ) : null}
      </ColFilter>
    </RowStyle>
  );
}

export default memo(FilterDowload);
