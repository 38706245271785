import { Row, Col, Form, Tooltip } from "antd";
import styled from "styled-components";

const Title = styled.div`
  color: black;
`;
const RowStyle = styled(Row)`
  margin-top: 10px;
`;

const ColFilter = styled(Col)`
  border-bottom: 1px solid #b3b3b3;
`;

const RowFilter = styled(Row)`
  margin-bottom: 10px;
  margin-top: 15px;
`;

const ColumnG = styled(Tooltip)`
  color: #6043f2;
  font-weight: bold;
`;

const ColumnD = styled(Tooltip)`
  color: #4365b3;
  font-weight: bold;
`;

const FormItem = styled(Form.Item)`
  font-weight: 500;
`;

export { Title, RowStyle, ColFilter, RowFilter, ColumnG, FormItem, ColumnD };
