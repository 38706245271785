import Supplier from "./screens/Supplier/Supplier";
import Wallet from "./screens/HistoryWallet/HistoryWallet";
import Dowload from "./screens/HistoryDowload/HistoryDowload";
import Product from "./screens/Product/Product";
import InsProduct from "./screens/Product/ModalAdd";
import UpdProduct from "./screens/Product/ModalUpd";
import Category from "./screens/Category/Category";
import Position from "./screens/Position/Position";
import updPosition from "./screens/Position/ModalUpd";
import insPosition from "./screens/Position/ModalAdd";

import EndUser from "./screens/EndUser/EndUser";
import updEndUser from "./screens/EndUser/ModalUpd";
import insEndUser from "./screens/EndUser/ModalAdd";

import Brand from "./screens/Brand/Brand";
import updBrand from "./screens/Brand/ModalUpd";
import insBrand from "./screens/Brand/ModalAdd";
import showImage from "./screens/Image/ShowImage";

import Blog from "./screens/Blog/Blog";
import updBlog from "./screens/Blog/ModalUpd";
import insBlog from "./screens/Blog/ModalAdd";

const routes = [
    {path: "/", name: "Supplier", component: Supplier, exact: true},
    {path: "/wallet", name: "Wallet", component: Wallet},
    {path: "/dowload", name: "Dowload", component: Dowload},
    {path: "/product", name: "product", component: Product},
    {path: "/insProduct", name: "InsProduct", component: InsProduct},
    {path: "/updProduct/:id", name: "UpdProduct", component: UpdProduct},
    {path: "/category", name: "Category", component: Category},
    {path: "/position", name: "Position", component: Position},
    {path: "/updPosition/:id", name: "updPosition", component: updPosition},
    {path: "/insPosition", name: "insPosition", component: insPosition},

    {path: "/endUser", name: "EndUser", component: EndUser},
    {path: "/updEndUser/:id", name: "updEndUser", component: updEndUser},
    {path: "/insEndUser", name: "insEndUser", component: insEndUser},

    {path: "/brand", name: "Brand", component: Brand},
    {path: "/updBrand/:id", name: "updBrand", component: updBrand},
    {path: "/insBrand", name: "insBrand", component: insBrand},
    {path: "/insBrand", name: "insBrand", component: insBrand},
    {path: "/image", name: "image", component: showImage},

    {path: "/blog", name: "Blog", component: Blog},
    {path: "/updBlog/:id", name: "updBlog", component: updBlog},
    {path: "/insBlog", name: "insBlog", component: insBlog},

];

export default routes;
