import axios from "../services/Api";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const initialState = {
  supplierId: null,
  dataSupplier: {},
};

export const getDataSupplier = createAsyncThunk(
  "get/DataSupplier",
  async () => {
    const result = await axios.get("/web/get?Auth");
    return result;
  }
);

export const addSupplier = createAsyncThunk(
  "add/SUPPLIER",
  async (body, { rejectWithValue }) => {
    try {
      const result = await axios.post("/web/save", body);
      return result;
    } catch (err) {
      return rejectWithValue(err.data.status);
    }
  }
);

export const supplierReducer = createSlice({
  name: "supplier",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
        .addCase(getDataSupplier.pending, (state) => {
          state.loading = true;
        })
        .addCase(getDataSupplier.fulfilled, (state, action) => {
          state.loading = false;
          const supplierData = action.payload.data?.data?.[0];
          if (supplierData) {
            state.supplierId = supplierData.id;
            state.dataSupplier = supplierData;
            localStorage.setItem("supplierId", supplierData.id);
          }
        })
        .addCase(getDataSupplier.rejected, (state) => {
          state.loading = false;
        });
  },

});

export const selectDataSupplier = (state) => state.supplierReducer.dataSupplier;
export const selectDataSupplierId = (state) => state.supplierReducer.supplierId;

export default supplierReducer.reducer;
