import React, {memo, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Breadcrumb, Col, Form, notification, Row} from "antd";
import {HomeOutlined} from "@ant-design/icons";
import {InputStyle} from "../../components/Styles/InputStyles";
import {RowStyle, Title} from "../../components/Styles/GeneralStyles";
import {FormItem} from "../../components/Styles/GeneralStyles";
import {Link, useNavigate} from "react-router-dom";
import ButtonAdd from "../../components/ButtonCustom/ButtonAdd";
import {getDataPositionById, insDataPosition, selectDataPositionById} from "../../reducers/positionReducer";
import {ErrorType} from "../../constant/ErrorType";

function UpdPosition() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const id = window.location.href.split("updPosition/")[1];

  const [form] = Form.useForm();
  const dataSelector = useSelector(selectDataPositionById);

  useEffect(() => {
    if (id) {
      dispatch(getDataPositionById(id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (dataSelector) {
      // Update the form's values once the data is fetched
      form.setFieldsValue({
        name: dataSelector.name || "",
      });
    }
  }, [dataSelector, form]);

  const onFinish = (value) => {
    const body = {
      name: value.name
    };
    dispatch(insDataPosition(body))
        .unwrap()
        .then(() => {
          notification.success({
            message: `Thêm vị trí ${ErrorType.SAVE200}`,
          });
          handleCancelUpd();
        })
        .catch((er) => {
          notification.error({
            message: `Thêm vị trí ${ErrorType.ERSAVE400} ${er.message} ${er.statusCode}`,
          });
        });
  };

  const handleCancelUpd = () => {
    form.resetFields();
    navigate("/position");
  };

  const link = [{
    title: (<Link to="/">
      <HomeOutlined/>
    </Link>),
  }, {
    title: (<Link to="/position">
      <b>Sản phẩm</b>
    </Link>),
  }, {
    title: <Title><b>Cập nhật vị trí</b></Title>,
  },];

  return (<>
    <Row span={24}>
      <Breadcrumb items={link}/>
    </Row>

    <RowStyle span={24} style={{marginTop: 20, paddingLeft: 50, paddingRight: 50}}>
      <Col xs={24} sm={24} md={18} lg={18} xl={20} xxl={20}>
        <Form
            form={form}
            onFinish={onFinish}
            labelCol={{span: 24}}
            wrapperCol={{span: 24}}
        >
          <FormItem
              label="Nhập vị trí lắp đặt"
              name="name"
              rules={[{required: true, message: "Nhập vị trí lắp đặt"}]}
          >
            <InputStyle placeholder="Vị trí lắp đặt"/>
          </FormItem>

          <Form.Item>
            <ButtonAdd title="Xác nhận"/>
          </Form.Item>
        </Form>
      </Col>
    </RowStyle>
  </>);
}

export default memo(UpdPosition);
