import axios from "../services/Api";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const initialState = {
    dataPosition: [],
    dataPositionById: [],
    loading: false,
};

export const getPosition = createAsyncThunk(
    "get/DataPosition",
    async (param) => {
        const result = await axios.get(
            `/position/get-all`
        );
        return result;
    }
);
export const insDataPosition = createAsyncThunk(
    "ins/DataPosition",
    async (body, { rejectWithValue }) => {
        try {
            const result = await axios.post("/position/save", body);
            return result;
        } catch (err) {
            return rejectWithValue(err.data.status);
        }
    }
);

export const getDataPositionById = createAsyncThunk(
    "byId/getDataPositionById",
    async (id) => {
        const result = await axios.get(`/position/get-id?id=${id}`);
        return result;
    }
);


export const positionReducer = createSlice({
    name: "otherPosition",
    initialState,
    reducers: {
        handlechangeShowFilter: (state, action) => {
            state.showFilter = action.payload;
        },
        handlechangeQuery: (state, action) => {
            state.query = action.payload;
        },
        handlechangePageSize: (state, action) => {
            state.pageSize = { ...state.pageSize, ...action.payload } || {};
        },

        handleChangeStatusDel: (state, action) => {
            state.statusDel = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            // getDataPosition
            .addCase(getPosition.pending, (state) => {
                state.loading = true;
            })
            .addCase(getPosition.fulfilled, (state, action) => {
                state.loading = false;
                state.totalSize = action.payload.data.totalSize;
                state.dataPosition = action.payload.data.data;
            })
            .addCase(getPosition.rejected, (state) => {
                state.loading = false;
            })

            // getDataProductById
            .addCase(getDataPositionById.pending, (state) => {
                state.loading = true;
            })
            .addCase(getDataPositionById.fulfilled, (state, action) => {
                state.loading = false;
                state.dataPositionById = action.payload.data.data;
            })
            .addCase(getDataPositionById.rejected, (state) => {
                state.loading = false;
            });
    },
});
export const {
    handlechangePageSize,

    handleChangeStatusDel,
} = positionReducer.actions;

export const selectDataPosition = (state) =>
    state.positionReducer.dataPosition;
export const selectDataPositionById = (state) =>
    state.positionReducer.dataPositionById;

export default positionReducer.reducer;
