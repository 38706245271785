import {configureStore} from "@reduxjs/toolkit";
import authReducer from "./authReducer";
import supplierReducer from "./supplierReducer";
import historyDowloadReducer from "./historyDowloadReducer";
import historyPaymentReducer from "./historyPaymentReducer";
import productReducer from "./productReducer";
import categoryPosterReducer from "./categoryPosterReducer";
import categoryReducer from "./categoryReducer";
import positionReducer from "./positionReducer";
import brandReducer from "./brandReducer";
import endUserReducer from "./endUserReducer";
import imageReducer from "./imageReducer";
import blogReducer from "./blogReducer";

export const store = configureStore({
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),

    reducer: {
        authReducer: authReducer,
        supplierReducer: supplierReducer,
        historyDowloadReducer: historyDowloadReducer,
        historyPaymentReducer: historyPaymentReducer,
        categoryReducer: categoryReducer,
        positionReducer: positionReducer,
        brandReducer: brandReducer,
        endUserReducer: endUserReducer,
        productReducer: productReducer,
        categoryPosterReducer: categoryPosterReducer,
        imageReducer: imageReducer,
        blogReducer: blogReducer,
    },
});
