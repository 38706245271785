import React from "react";
import { Layout, Menu } from "antd";
import { Link } from "react-router-dom";
import {
  HomeOutlined,
  ShoppingCartOutlined,
  CrownOutlined,
  WindowsOutlined,
  PictureOutlined,
} from "@ant-design/icons";
import "./DefaultLayout.css";
const { Sider } = Layout;

export default function DefaultSidebar({ collapsed }) {
  const getItem = (label, key, icon, children) => {
    return { label, key, icon, children };
  };
  const items = [
    getItem(
      <Link to={"/"}>Thông tin Web</Link>,
      "1",
      <HomeOutlined className="icon-color" />
    ),
    getItem(
      <Link to={"/product"}>Sản phẩm</Link>,
      "6",
      <CrownOutlined className="icon-color" />
    ),
    getItem("Thông tin chung", "8", <WindowsOutlined className="icon-color" />, [
      getItem(<Link to={"/category"}>Danh mục</Link>, "8-1"),
      getItem(<Link to={"/position"}>Vị trí lắp đặt</Link>, "8-2"),
      getItem(<Link to={"/brand"}>Thương hiệu</Link>, "8-3"),
      getItem(<Link to={"/endUser"}>Đối tượng sử dụng</Link>, "8-4"),
    ]),
    getItem(
        <Link to={"/image"}>Ảnh</Link>,
        "9",
        <PictureOutlined className="icon-color" />
    ),
    // getItem("Ảnh", "9", <PictureOutlined className="icon-color" />, [
    //   getItem(<Link to={"/img-background"}>Ảnh background</Link>, "9-1"),
    //   getItem(<Link to={"/img-header"}>Ảnh header</Link>, "9-2"),
    //   getItem(<Link to={"/img-top"}>Ảnh top 3 danh mục</Link>, "9-3"),
    // ]),
    getItem(
        <Link to={"/blog"}>Blog</Link>,
        "11",
        <ShoppingCartOutlined className="icon-color" />
    ),
    getItem(
      <Link to={"/dowload"}>Đơn hàng đã đặt</Link>,
      "10",
      <ShoppingCartOutlined className="icon-color" />
    )
  ];

  return (
    <Sider trigger={null} collapsible collapsed={collapsed}>
      {/*<ImageStyle src={logo} preview={false} />*/}
      <Menu mode="inline" theme="dark" items={items} />
    </Sider>
  );
}

// const ImageStyle = styled(Image)`
//   margin: 10px;
//   padding-top: 12px;
// `;
