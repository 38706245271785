import React from "react";
import "./App.css";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import { Provider } from "react-redux";
import { store } from "./reducers";
import decode from "jwt-decode";
import HomeLayout from "./screens/DefaultLayout/DefaultLayout";
import Login from "./screens/Auth/Login/Login";

const checkAuth = () => {
  const token = localStorage.getItem("token");
  const role = localStorage.getItem("role");
  if (!token || role !== "ADMIN") {
    return false;
  }
  try {
    const { exp } = decode(token);
    if (exp < parseInt(new Date().getTime() / 1000)) {
      return false;
    }
  } catch (e) {
    return false;
  }
  return true;
};

function App() {
  return (
      <Provider store={store}>
        <BrowserRouter>
          <Routes>
            <Route path="/login" element={<Login />} />

            {/* Protect the root route */}
            <Route
                path="*"
                element={checkAuth() ? <HomeLayout /> : <Navigate to="/login" replace />}
            />

            {/* Fallback route */}
            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
        </BrowserRouter>
      </Provider>
  );
}

export default App;
