import axios from "axios";

// Create an Axios instance with custom configuration
const api = axios.create({
    baseURL: process.env.REACT_APP_API_KEY,
    headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Cache: "no-cache",
    },
    timeout: 30000,
});

// Add a request interceptor
api.interceptors.request.use(
    function (config) {
        const token = localStorage.getItem("token");
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    function (error) {
        return Promise.reject(error);
    }
);

// Add a response interceptor
api.interceptors.response.use(
    (response) => response,
    (error) => {
        // Handle error response
        return Promise.reject(error?.response || error);
    }
);

export default api;
