import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  deleteDataImageById,
  getImage, selectDataImage
} from "../../reducers/imageReducer";
import imgDefault from "../../assets/android-icon-48x48.png";
import { Breadcrumb, Col, Popover, Row, Space, Table, Tooltip, notification } from "antd";
import { EditOutlined, HomeOutlined, DeleteOutlined } from "@ant-design/icons";
import { RowStyle, Title } from "../../components/Styles/GeneralStyles";
import ButtonAdd from "../../components/ButtonCustom/ButtonAdd";
import { PopoverDelete, PopoverEdit } from "../../components/Styles/PopoverStyles";
import ModalAdd from "./ModalAdd";
import ModalUpd from "./ModalUpd";
import {ErrorType} from "../../constant/ErrorType";
function ShowImage() {
  const dispatch = useDispatch();
  const data = useSelector(selectDataImage);
  // const sizeSelector = useSelector(selectTotalSizeImg);
  const [showModalAdd, setShowModalAdd] = useState(false);
  const [showModalUpd, setShowModalUpd] = useState({ show: false, id: null });
  const API_URL_HTTPS = 'https://maylocnuocg7.com:8088';

  const dataTable = data?.map((e) => ({
    key: e.id,
    id: e.id,
    type: e.type,
    image: e.image,
  }));

  useEffect(() => {
    dispatch(getImage());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showModalAdd, showModalUpd.show]);

  const onShowModalUpd = (record) => {
    setShowModalUpd({ show: true, id: record.id });
  };

  const onCancelModalUpd = () => {
    setShowModalUpd({ show: false, id: null });
  };

  const onShowModalAdd = () => {
    if (4 > 5) {
      notification.error({
        message: `Chỉ upload được 3 ảnh`,
      });
    } else {
      setShowModalAdd(true);
    }
  };

  const onCancelModalAdd = () => {
    setShowModalAdd(false);
  };

  const onDeleteImage = (record) => {
    dispatch(deleteDataImageById(record.id))
      .unwrap()
      .then((e) => {
        notification.success({
          message: `Xóa ảnh ${ErrorType.SAVE200}`,
        });
        dispatch(getImage());
      })
      .catch((er) => {
        notification.error({
          message: `Xóa ảnh ${ErrorType.ERSAVE400} ${er.statusCode}`,
        });
      });
  };

  const columns = [
    {
      title: "STT",
      dataIndex: "stt",
      key: "stt",
      render: (t, r, i) => i + 1,
      width: "80px",
      ellipsis: {
        showTitle: false,
      },
    },
    {
      title: "Ảnh",
      dataIndex: "image",
      key: "image",
      width: "160px",
      ellipsis: {
        showTitle: false,
      },
      render: (value) => (
        <img
          alt=""
          width={120}
          height={140}
          style={{ borderRadius: 5 }}
          src={value ? `${API_URL_HTTPS}/${value}` : imgDefault}
        />
      ),
    },
    {
      title: "Link ảnh",
      dataIndex: "image",
      key: "image",
      width: "400px",
      ellipsis: { showTitle: false },
      render: (value) => (
        <Tooltip>
          <a href={`${process.env.REACT_APP_API_KEY}/${value}`} target="_blank" rel="noreferrer">
            {`${process.env.REACT_APP_API_KEY}/${value}`}
          </a>
        </Tooltip>
      ),
    },
    {
      title: "Vị trí",
      dataIndex: "type",
      key: "type",
      width: "400px",
      ellipsis: { showTitle: false }
    },

    {
      title: "Chức năng",
      key: "action",
      fixed: "right",
      width: "50px",
      render: (record) => (
        <Space>
          <PopoverEdit content="Upload ảnh">
            <EditOutlined onClick={() => onShowModalUpd(record)} />
          </PopoverEdit>
          <Popover content="Xóa ảnh">
            <PopoverDelete title="Xác nhận xóa" onConfirm={() => onDeleteImage(record)}>
              <DeleteOutlined />
            </PopoverDelete>
          </Popover>
        </Space>
      ),
    },
  ];

  const link = [
    {
      title: (
        <>
          <Link to={"/"}>
            <HomeOutlined />
          </Link>
        </>
      ),
    },
    {
      title: (
        <Title>
          <b>Danh sách ảnh</b>
        </Title>
      ),
    },
  ];

  return (
    <>
      <Row span={24}>
        <Breadcrumb items={link} />
      </Row>

      <RowStyle span={24}>
        <Col span={12} />
        <Col span={12}>
          <ButtonAdd onClick={onShowModalAdd} />
        </Col>
      </RowStyle>

      <RowStyle span={24}>
        <Col span={24}>
          <Table
            columns={columns}
            dataSource={dataTable}
            size="small"
            scroll={{ x: "calc(350px + 50%)" }}
          />
        </Col>
      </RowStyle>
      <ModalAdd show={showModalAdd} handleCancel={onCancelModalAdd} />
      <ModalUpd show={showModalUpd.show} id={showModalUpd.id} handleCancel={onCancelModalUpd} />
    </>
  );
}

export default ShowImage;
