import React, {memo} from "react";
import {useDispatch} from "react-redux";
import {Breadcrumb, Col, Form, Row, notification} from "antd";
import {HomeOutlined} from "@ant-design/icons";
import {
    InputStyle,
} from "../../components/Styles/InputStyles";
import {ErrorType} from "../../constant/ErrorType";
import {FormItem} from "../../components/Styles/GeneralStyles";
import {Link, useNavigate} from "react-router-dom";
import {RowStyle, Title} from "../../components/Styles/GeneralStyles";
import ButtonAdd from "../../components/ButtonCustom/ButtonAdd";
import {insDataEndUser} from "../../reducers/endUserReducer";

function InsProduct() {
    const dispatch = useDispatch();
    const navigate = useNavigate(); // useNavigate instead of history
    const [form] = Form.useForm();

    const onFinish = (value) => {
        const body = {
            name: value.name
        };
        dispatch(insDataEndUser(body))
            .unwrap()
            .then(() => {
                notification.success({
                    message: `Thêm người dùng cuối ${ErrorType.SAVE200}`,
                });
                handleCancelAdd();
            })
            .catch((er) => {
                notification.error({
                    message: `Thêm người dùng cuối ${ErrorType.ERSAVE400} ${er.message} ${er.statusCode}`,
                });
            });
    };

    const handleCancelAdd = () => {
        form.resetFields();
        navigate("/endUser");
    };

    const link = [
        {
            title: (
                <Link to="/">
                    <HomeOutlined/>
                </Link>
            ),
        },
        {
            title: (
                <Link to="/endUser">
                    <b>Đối tượng sử dụng</b>
                </Link>
            ),
        },
        {
            title: <Title><b>Thêm mới</b></Title>,
        },
    ];

    return (
        <>
            <Row span={24}>
                <Breadcrumb items={link}/>
            </Row>

            <RowStyle span={24} style={{marginTop: 20, paddingLeft: 50, paddingRight: 50}}>
                <Col xs={24} sm={24} md={18} lg={18} xl={20} xxl={20}>
                    <Form
                        form={form}
                        onFinish={onFinish}
                        labelCol={{span: 24}}
                        wrapperCol={{span: 24}}
                    >
                        <FormItem
                            label="Nhập thông tin"
                            name="name"
                            rules={[{required: true, message: "Người dùng cuối"}]}
                        >
                            <InputStyle placeholder="Người dùng cuối"/>
                        </FormItem>

                        <Form.Item>
                            <ButtonAdd title="Xác nhận"/>
                        </Form.Item>
                    </Form>
                </Col>
            </RowStyle>
        </>
    );
}

export default memo(InsProduct);
