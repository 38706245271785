import React from "react";
import { Link } from "react-router-dom";
import { Title } from "../../components/Styles/GeneralStyles";
import { HomeOutlined } from "@ant-design/icons";
import { Breadcrumb, Row } from "antd";

import ModalAdd from "./ModalAdd";
import ModalUpd from "./ModalUpd";

import TableCategory from "./TableCategory";
function Category() {
  const link = [
    {
      title: (
        <>
          <Link to={"/"}>
            <HomeOutlined />
          </Link>
        </>
      ),
    },
    {
      title: (
        <Title>
          <b>Danh mục poster</b>
        </Title>
      ),
    },
  ];

  return (
    <>
      <Row span={24}>
        <Breadcrumb items={link} />
      </Row>
      <TableCategory />
      <ModalAdd />
      <ModalUpd />
    </>
  );
}
export default Category;
