import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  handlechangePageSize,
  selectDataPayment,
  selectPageSize,
  selectTotalSize,
} from "../../reducers/historyPaymentReducer";
import { memo } from "react";
import { Col, Table, Tooltip } from "antd";
import moment from "moment";
import { StatusStyles } from "../../components/Styles/StatusStyles";
import formatMoney from "../../constant/ConvertMoney";
import { RowStyle } from "../../components/Styles/GeneralStyles";

const FormatMoney = (value) => {
  let data = parseInt(value);
  return formatMoney(data);
};

function TableWallet() {
  const dispatch = useDispatch();
  const dataHistoryPayment = useSelector(selectDataPayment);
  const sizeSelector = useSelector(selectTotalSize);
  const pageSize = useSelector(selectPageSize);

  const dataTable = dataHistoryPayment?.map((e) => ({
    key: e.id,
    id: e.id,
    userName: e.userName,
    createDate: e.createDate,
    packageName: e.packageName,
    bankName: e.bankName,
    price: e.price,
    content: e.content,
    status: e.status,
  }));

  const onChangePageSize = (page, size) => {
    dispatch(
      handlechangePageSize({
        page: page - 1,
        size: size,
      })
    );
  };

  const columns = [
    {
      title: "STT",
      dataIndex: "stt",
      render: (t, r, i) => i + 1,
      width: "50px",
      ellipsis: {
        showTitle: false,
      },
    },
    {
      title: "Tài khoản",
      dataIndex: "userName",
      key: "userName",
      width: "250px",
      ellipsis: {
        showTitle: false,
      },
      render: (value) => (
        <Tooltip style={{ color: "#0288D1" }} placement="topLeft" title={value}>
          {value}
        </Tooltip>
      ),
    },

    {
      title: "Thời gian",
      dataIndex: "createDate",
      key: "createDate",
      width: "150px",
      ellipsis: {
        showTitle: false,
      },
      render: (value) => (
        <Tooltip placement="topLeft" title={moment(value).format("DD/MM/YYYY - HH:mm")}>
          {moment(value).format("DD/MM/YYYY - HH:mm")}
        </Tooltip>
      ),
    },
    {
      title: "Tên gói",
      dataIndex: "packageName",
      key: "packageName",
      width: "150px",
      ellipsis: {
        showTitle: false,
      },
      render: (value) => (
        <Tooltip placement="topLeft" title={value}>
          {value}
        </Tooltip>
      ),
    },
    {
      title: "Phương thức",
      dataIndex: "bankName",
      key: "bankName",
      width: "150px",
      ellipsis: {
        showTitle: false,
      },
      render: (value) => (
        <Tooltip placement="topLeft" title={value}>
          {value}
        </Tooltip>
      ),
    },
    {
      title: "Số tiền",
      dataIndex: "price",
      key: "price",
      width: "200px",
      ellipsis: {
        showTitle: false,
      },
      render: (value) => (
        <Tooltip placement="topLeft" title={FormatMoney(value)}>
          {FormatMoney(value)} VNĐ
        </Tooltip>
      ),
    },
    {
      title: "Nội dung",
      dataIndex: "content",
      key: "content",
      width: "300px",
      ellipsis: {
        showTitle: false,
      },
      render: (value) => (
        <Tooltip placement="topLeft" title={value}>
          {value}
        </Tooltip>
      ),
    },
    {
      title: "Trạng thái",
      dataIndex: "status",
      key: "status",
      ellipsis: {
        showTitle: false,
      },
      width: "150px",
      render: (value) => (
        <StatusStyles dataStatus={value}>
          {value ? "Đã thanh toán" : "Chưa thanh toán"}
        </StatusStyles>
      ),
    },
  ];
  return (
    <RowStyle span={24}>
      <Col span={24}>
        <Table
          columns={columns}
          dataSource={dataTable}
          size="small"
          pagination={{
            onChange: onChangePageSize,
            current: pageSize.page + 1,
            pageSize: pageSize?.size,
            total: sizeSelector,
            showSizeChanger: true,
            showTotal: (total) => <div>{`Tổng bản ghi: ${total}`}</div>,
          }}
          scroll={{ x: "calc(350px + 50%)" }}
        />
      </Col>
    </RowStyle>
  );
}

export default memo(TableWallet);
