import React from "react";
import { Link } from "react-router-dom";
import { Breadcrumb, Row } from "antd";
import { HomeOutlined } from "@ant-design/icons";

import { Title } from "../../components/Styles/GeneralStyles";
import FilterDowload from "./FilterDowload";
import TableDowload from "./TableDowload";

export default function HistoryDowload() {
  const link = [
    {
      title: (
        <>
          <Link to={"/"}>
            <HomeOutlined />
          </Link>
        </>
      ),
    },
    {
      title: (
        <Title>
          <b>Lịch sử tải</b>
        </Title>
      ),
    },
  ];

  return (
    <>
      <Row span={24}>
        <Breadcrumb items={link} />
      </Row>

      <FilterDowload />

      <TableDowload />
    </>
  );
}
