import React, { memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  delDataProduct,
  handleChangeStatusDel,
  handlechangePageSize,
  selectDataProduct,
  selectPageSize,
  selectTotalSize,
} from "../../reducers/productReducer";
import { Col, Popover, Space, Table, Tooltip, notification } from "antd";
import { ErrorType } from "../../constant/ErrorType";
import { StatusStyles } from "../../components/Styles/StatusStyles";
import formatMoney from "../../constant/ConvertMoney";
import { useNavigate } from "react-router-dom";
import imgDefault from "../../assets/android-icon-48x48.png";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import {
  PopoverDelete,
  PopoverEdit,
} from "../../components/Styles/PopoverStyles";
import { RowStyle } from "../../components/Styles/GeneralStyles";

const FormatMoney = (value) => {
  let data = parseInt(value);
  return formatMoney(data);
};

function TableProduct() {
  const dispatch = useDispatch();
  const navigate = useNavigate();  // Updated from useHistory to useNavigate
  const sizeSelector = useSelector(selectTotalSize);
  const pageSize = useSelector(selectPageSize);
  const dataProduct = useSelector(selectDataProduct);

  const dataTable = dataProduct?.map((e) => ({
    key: e.id,
    id: e.id,
    status: e.status,
    cateName: e.cateName,
    image: e.image,
    name: e.name,
    title: e.title,
    description: e.description,
    price: e.price,
    salePrice: e.salePrice,
    salePercentage: e.salePercentage,
    active: e.active,
    hot: e.hot,
  }));

  const onChangePageSize = (page, size) => {
    dispatch(
        handlechangePageSize({
          page: page - 1,
          size: size,
        })
    );
  };

  const onShowModalUpd = (record) => {
    navigate(`/updProduct/${record?.id}`, { state: { id: record?.id } });
  };

  const onDeleteProduct = (record) => {
    dispatch(delDataProduct(record.id))
        .unwrap()
        .then(() => {
          notification.success({
            message: `Xóa sản phẩm ${ErrorType.SAVE200}`,
          });
          onChangeStatus(record.id);
        })
        .catch((er) => {
          notification.error({
            message: `Xóa sản phẩm ${ErrorType.ERSAVE400} ${er.statusCode}`,
          });
        });
  };

  const onChangeStatus = (id) => {
    const title = "del" + id.toString();
    dispatch(handleChangeStatusDel(title));
  };

  const columns = [
    {
      title: "STT",
      dataIndex: "stt",
      key: "stt",
      render: (t, r, i) => i + 1,
      width: "50px",
      ellipsis: {
        showTitle: false,
      },
    },
    {
      title: "Hình ảnh",
      dataIndex: "image",
      key: "image",
      ellipsis: {
        showTitle: false,
      },
      render: (value) => (
          <img
              alt=""
              width={32}
              height={30}
              style={{ borderRadius: 5 }}
              src={value ? `${process.env.REACT_APP_API_KEY}/${value}` : imgDefault}
          />
      ),
      width: "100px",
    },
    {
      title: "Tên sản phẩm",
      dataIndex: "name",
      key: "name",
      width: "220px",
      ellipsis: { showTitle: false },
      render: (value) => (
          <Tooltip placement="topLeft" title={value}>
            {value}
          </Tooltip>
      ),
    },
    {
      title: "Giá bán",
      dataIndex: "price",
      key: "price",
      width: "180px",
      ellipsis: { showTitle: false },
      // defaultSortOrder: "descend",
      sorter: (a, b) => a.price - b.price,
      render: (value) => (
          <Tooltip placement="topLeft" title={FormatMoney(value) + " VND"}>
            <b>{FormatMoney(value)} VND</b>
          </Tooltip>
      ),
    },
    {
      title: "Giá giảm",
      dataIndex: "salePrice",
      key: "salePrice",
      width: "180px",
      ellipsis: { showTitle: false },
      // defaultSortOrder: "descend",
      sorter: (a, b) => a.salePrice - b.salePrice,
      render: (value) => (
          <Tooltip placement="topLeft" title={FormatMoney(value) + " VND"}>
            <b>{FormatMoney(value)} VND</b>
          </Tooltip>
      ),
    },
    {
      title: "% giảm",
      dataIndex: "salePercentage",
      key: "salePercentage",
      width: "120px",
      ellipsis: { showTitle: false },
      // defaultSortOrder: "descend",
      sorter: (a, b) => a.salePercentage - b.salePercentage,
      render: (value) => (
          <Tooltip>
            <b>{value} %</b>
          </Tooltip>
      ),
    },
    {
      title: "Tình trạng",
      dataIndex: "status",
      key: "status",
      width: "120px",
      ellipsis: { showTitle: false },
      render: (value) => (
          <StatusStyles dataStatus={value === "Còn hàng"}>
            {value}
          </StatusStyles>
      ),
    },
    {
      title: "Thể loại",
      dataIndex: "cateName",
      key: "cateName",
      width: "160px",
      ellipsis: { showTitle: false },
      render: (value) => (
          <Tooltip placement="topLeft" title={value}>
            {value}
          </Tooltip>
      ),
    },
    {
      title: "Trạng thái",
      dataIndex: "active",
      key: "active",
      width: "80px",
      filters: [
        { text: "Hiện", value: true },
        { text: "Ẩn", value: false },
      ],
      onFilter: (value, record) => record.active === value,
      render: (value) => (
          <StatusStyles dataStatus={value}>
            {value === true ? "Hiện" : "Ẩn"}
          </StatusStyles>
      ),
    },
    {
      title: "Sản phẩm hot",
      dataIndex: "hot",
      key: "hot",
      width: "80px",
      filters: [
        { text: "Yes", value: true },
        { text: "No", value: false },
      ],
      onFilter: (value, record) => record.hot === value,
      render: (value) => (
          <StatusStyles dataStatus={value}>
            {value === true ? "Yes" : "No"}
          </StatusStyles>
      ),
    },
    {
      title: "Chức năng",
      key: "action",
      fixed: "right",
      width: "120px",
      render: (record) => (
        <Space>
          <PopoverEdit content="Chi tiết và chỉnh sửa thông tin">
            <EditOutlined onClick={() => onShowModalUpd(record)} />
          </PopoverEdit>

          <Popover content="Xóa sản phẩm">
            <PopoverDelete
              title="Xác nhận xóa"
              onConfirm={() => onDeleteProduct(record)}
            >
              <DeleteOutlined />
            </PopoverDelete>
          </Popover>
        </Space>
      ),
    },
  ];

  return (
      <RowStyle span={24}>
        <Col span={24}>
          <Table
              columns={columns}
              dataSource={dataTable}
              size="small"
              pagination={{
                onChange: onChangePageSize,
                current: pageSize.page + 1,
                pageSize: pageSize?.size,
                total: sizeSelector,
                showSizeChanger: true,
                showTotal: (total) => <div>{`Tổng bản ghi: ${total}`}</div>,
              }}
              scroll={{ x: "calc(350px + 50%)" }}
          />
        </Col>
      </RowStyle>
  );
}

export default memo(TableProduct);
